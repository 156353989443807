.create-mission {
  .slide-one {
    .content {
      width: 465px;

      .block {
        &-office {
          z-index: 4;
        }

        &-profession {
          .ListSelect {
            margin-bottom: 70px;

            &__input {
              font-family: "Arial Rounded MT Bold", sans-serif;
              border: none;
              font-size: 14px;
              border-bottom: 1px solid $blue;
              box-sizing: border-box;
              padding: 0;
              padding-bottom: 5px;
              color: $blue;
              &::placeholder {
                font-size: 14px;
                font-family: "Arial Rounded MT Bold", sans-serif;
                color: $grey3;
              }
            }
          }
          z-index: 3;
        }

        &-reason {
          z-index: 2;
          .ListSelect {
            margin-bottom: 60px;
          }
        }

        &-mission-type {
          z-index: 1;
        }
      }
    }

    .description {
      font-size: 12px;
    }

    .mission-type {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }

    .checkbox-blue,
    .checkbox {
      min-width: 135px;
      margin: 10px 20px 10px 0;
    }
  }
}
