.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-four {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .finess-description {
    max-width: 600px;
    margin-bottom: 50px;
    line-height: 25px;
    text-align: justify;
  }

  .finess-link,
  .finess-link a {
    color: $main-green;
  }

  .ets-input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .ets-loading {
      margin-top: 5px;
      margin-left: 20px;
      display: flex;
      justify-content: center;
    }
  }

  .pagination {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    color: $grey2;
    border-bottom: 1px solid $grey4;
    padding: 10px 0;

    div {
      font-size: 12px;
      line-height: 15px;
    }

    .types-label {
      height: 40px;
      padding: 0 20px;
      border-radius: 50px;
      font-family: $main-font;
      border: 1px solid $peach;
      color: $grey;
      margin-bottom: 20px;
    }

    .filter {
      > span {
        color: $blue;
        border: $blue 1px solid;
        padding: 3px 10px;
        border-radius: 15px;
        transition: all ease 0.5s;
        margin-bottom: 10px;

        &:hover {
          cursor: pointer;
          border-color: $orange;
          background-color: $orange;
          color: white;

          &:before {
            content: " x ";
          }
        }
      }
    }

    .numberOfResults div {
      font-size: 15px;
      line-height: 20px;
      max-width: 600px;
    }
  }

  .results {
    display: flex;

    .office-card-row {
      display: flex;
      justify-content: space-between;
      width: 800px;
    }
  }
}

.slide-four .establishment {
  padding: 25px 25px 0 25px;
  cursor: pointer;
}
.slide-four .establishment:hover {
  background-color: $peach;
  color: white;
  border-radius: 20px;
}

.slide-four .establishment::after {
  content: "";
  display: block;
  width: 250px;
  height: 1px;
  background-color: $peach;
  margin: 25px auto 0 auto;
}

.slide-four .establishment-name {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 10px;
}

.slide-four .establishment-city,
.slide-four .establishment-street {
  font-family: $light-font;
  line-height: 18px;
  font-weight: 500;
}

.slide-four .content h3 {
  font-size: 36px;
  line-height: 42px;
  padding: 40px 0 20px 0;
}

.slide-four .content h4 {
  font-size: 21px;
  line-height: 24px;
  padding: 20px 0 30px 0;
}

.slide-four .content .invalid {
  font-size: 12px;
  padding-left: 20px;
  color: $orange;
}

.slide-four .is-not-created {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
}

.slide-four .checkbox {
  margin-right: 0;
}

.slide-four .already-created {
  font-size: 14px;
  color: $orange;
  margin-top: 25px;
  margin-left: 20px;
  max-width: 435px;
}

.slide-four p {
  color: $grey;
  margin-top: 10px;
  // z-index: -5;
}

.slide-four a {
  text-decoration: underline;
  /* color: $orange; */
}
