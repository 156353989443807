.create-mission .content {
  display: flex;
  flex-direction: column;
  position: relative;
}

.create-mission .top-logo {
  position: absolute;
  width: 150px;
  top: 45px;
  left: 100px;
}

.create-mission .backward {
  position: absolute;
  top: 18.6vh;
  left: 70px;
  color: $main-green;
  font-size: 24px;
  cursor: pointer;
}

.create-mission i {
  margin-right: 20px;
}

.create-mission .content {
  display: flex;
  flex-direction: column;
  margin: 13vh 50px 50px 350px;
  position: relative;
}

.create-mission .content h3 {
  font-size: 36px;
  line-height: 42px;
  padding: 40px 0;
}

.create-mission .content h4 {
  font-size: 21px;
  line-height: 24px;
  padding: 20px 0 15px 0;
}

.create-mission.content p {
  text-align: justify;
  padding: 0;
  margin-bottom: 30px;
  font-size: 18px;
  /*14px is better looking*/
  line-height: 21px;
  max-width: 600px;
}

.create-mission form {
  display: flex;
  flex-direction: column;
}

.create-mission .validate-terms {
  width: fit-content;
  margin-top: 20px;
}

.error {
  font-size: 12px;
  color: $orange;
}

.errors-block {
  height: 10px;
  padding-bottom: 5px;

  &.remuneration-amount div {
    position: absolute;
  }
}

@media only screen and (max-width: 1024px) {
  .create-mission .top-logo {
    position: absolute;
    width: 150px;
    bottom: 50px;
    left: 100px;
  }

  .create-mission .content {
    margin: 100px auto 0 auto;
  }

  .create-mission .backward {
    position: absolute;
    top: 150px;
    left: 80px;
    color: $main-green;
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .create-mission .content {
    margin: 150px auto 0 auto;
  }

  .create-mission .backward {
    position: absolute;
    top: 150px;
    left: 40px;
    color: $main-green;
    font-size: 30px;
  }

  .create-mission .backward>span {
    display: none;
  }
}
