.buttons-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.buttons-list .is-checked {
  padding: 10px 50px;
  border: 1px solid $blue;
  border-radius: 20px;
  font-size: 12px;
  margin: 0 20px 30px 0;
  position: relative;
  cursor: pointer;
}

.buttons-list .is-not-checked {
  padding: 10px 50px;
  border: 1px solid $light-grey;
  border-radius: 20px;
  font-size: 12px;
  margin: 0 20px 30px 0;
  position: relative;
  cursor: pointer;
}

.buttons-list .check-box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $light-grey;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  top: 6px;
  right: 12px;
}

.buttons-list .check-box-blue {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $blue;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  top: 6px;
  right: 12px;
}

.buttons-list .inner-check-box-blue {
  background-color: $blue;
  border: 1px solid $blue;
  height: 12px;
  width: 12px;
  border-radius: 6px;
}

.buttons-list .inner-check-box {
  background-color: transparent;
  border: 1px solid $light-grey;
  height: 12px;
  width: 12px;
  border-radius: 6px;
}
