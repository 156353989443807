@mixin base-list($color: $peach) {
  position: absolute;
  overflow: hidden;
  z-index: 8;
  background-color: white;
  border: 1px solid $color;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  top: 10px;
  border-top: none;
  padding-top: 30px;
  scrollbar-width: thin;
  scrollbar-color: $color white;   
}

@mixin base-calendar($color: $peach) {
  color: $peach;
  border: 1px solid $color;
  position: absolute;
  overflow: hidden;
  width: 300px;
  border-top: none;
  border-radius: 0 0 20px 20px;
  z-index: 3;
  padding-top: 20px;
  margin-top: -35px;
}

@mixin base-confirmed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  font-size: 12px;
  margin: 0 10px 10px 0;
  padding: 10px 20px;
  border: 1px solid $blue;
  border-radius: 20px;
  i {
    color: $orange2;
    font-size: 12px;
    margin: 0;
    &:hover {
      cursor: pointer;
      color: $orange;
    }
  }
}

// DATE BLOCK
.create-mission {
  .slide-two {
    // BASE
    .content {
      h4 {
        margin: 5px 0 15px 0;
        padding: 10px 0;
      }
      
      h3 {
        padding: 40px 0 20px 0;
      }
    }

    // PICK DATE CALENDAR
    .calendars-div {
      margin-bottom: 20px;

      .time {
        display: flex;
        flex-direction: row;
      }
  
      .calendars {
        display: flex;

        .input-block{
          width: 300px;
          height: 40px;
        }
        
        .calendar {
          z-index: 4!important;
          overflow-x: visible!important;
          
          .orange-calendar {
            @include base-list;
          }
          
          .blue-calendar {
            @include base-list($blue);
          }
          .react-calendar__month-view__days__day--weekend {
            color: $grey;
            font-family: $light-font;
            font-size: 12px;
          }

          .react-calendar__month-view__days__day--neighboringMonth {
            background-color: $grey5;
            font-family: $light-font;
            font-size: 12px;
          }


          .react-calendar__tile:hover {
            background-color: $peach3;
          }
          
          .react-calendar__tile:disabled {
            background-color: $grey4;
            z-index: 3;
          }
    
          .react-calendar__tile--active {
            background-color: $peach;
            border-radius: 5px;
            color: white;
            &:hover {
              background-color: $peach;
            }
          }
    
          button {
            color: $grey;
            font-family: $light-font;
            font-size: 12px;
          }
        }

        .start-calendar {
          margin-right: 50px;
        }
    
        .start-calendar, .end-calendar {
          position: relative;
          z-index: 10;
    
          i {
            z-index: 8;
          }
    
          .blue-input, .orange-input {
            width: 248px;
            z-index: 5;
            cursor: pointer;
          }
        }

        .end-calendar {  
          i {
            z-index: 9;
          }  
          .blue-input {
            width: 248px;
            z-index: 9;
            cursor: pointer;
          }
        }
        
      }
    }
    
    // PART-TIME CALENDAR
    .part-time {
      margin: 30px 0;
      table {
        th, td {
          padding: 6px 40px 6px 0;
        }
        
        th {
          padding-bottom: 15px;
          text-align: center;
        }
        
        td {
          vertical-align: middle;
          text-align: center;
          
          &.part-day {
            text-align: right;
            padding-right: 30px;
          }
    
    
          div {
            display: flex;
            justify-content: center;  
            .checkbox, .checkbox-blue {
              margin: 0 auto;
              .circle {
                margin: 0;
              }
            }
          }
        }
      }
    
      .error {
        margin-top: 10px;
      }
    }

    // SKILLS
    .skills {
      .input-block {
        width: 410px;
        i {
          z-index: 9;
        }
      }

      .list-global ul {
        z-index: 8;
      }

      .list {
        padding-top: 0;
      }
    }

    .skill {
      width: 368px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0 10px 40px;
    }

    .orange-skills-list {
      @include base-list;
      position: absolute;
      width: 408px;
    }

    .blue-skills-list {
      @include base-list($blue);
      position: absolute;
      width: 408px;
    }
    
    .orange-skills-list::-webkit-scrollbar,
    .blue-skills-list::-webkit-scrollbar {
      width: 0.8em;
    }
    
    .orange-skills-list::-webkit-scrollbar-thumb {
      background-color: $peach;
      border-radius: 10px;
    }
    
    .blue-skills-list::-webkit-scrollbar-thumb {
      background-color: $blue;
      border-radius: 10px;
    }

    .all-confirmed-skills {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 750px;
      max-height: 80px;
    }

    .confirmed-skill {
      @include base-confirmed;
    }

    // ACTS BLOCK
    .acts-per-week {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      &>div{
        margin-right: 20px;
      }

      .input-block {
        width: 100px;
        margin: 0 20px 10px 0;
      }

      .blue-input, .orange-input {
        margin: 0;
      }
    }

    // REMUNERATION BLOCK
    .remuneration {
      position: relative;
      display: flex;
      margin-top: 15px;

      .remuneration-type {
        width: 262px;

        .remunerations-list {
          width: 260px;
          height: 42px;
          top: -5px;
          margin-bottom: 18px;
        }  
      }

      .remuneration-amount {
        margin-left: 15px;
        width: 126px;

        .errors-block {
          margin: 0;
        }
      }
    
      .remuneration-comment {
        width: 260px;
        margin-left: 15px;
        
        .input-block {
          width: 300px;
    
          &.grey-input {
            input {
              border: 1px solid $grey3;
              caret-color: $grey;
              cursor: text;
              &::placeholder {
                color: $grey3;
                font-family: $main-font;
              }
            }
          }
        }
      }

      i {
        z-index: 7;
      }

      .input-block {
        width: 110px;
      }

      &.internship {
        .input-block {
          padding-top: 0;
        }
      }

      .blue-remuneration-list {
        position: absolute;
        left: 0;
        font-size: 12px;
        text-align: center;
        width: 258px;
        z-index: 3;
        background-color: white;
        margin-top: 40px;
        border: 1px solid $blue;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top: none;
        padding-top: 20px;
        cursor: pointer;
      }

      .type {
        padding: 10px 0;
        
        &:hover {
          color: white;
          background-color: $peach;
          border-radius: 20px;
        }
      }


      .length-error {
        color: $orange;
        font-size: 12px;
        margin: -10px 0 0 15px;
      }

      input.remuneration-input {
        border: none;
        width: 60px;
        font-family: $main-font;
        &::-webkit-input-placeholder {
          font-family: $main-font;
        }
      }
      
      .remuneration-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    // INTERNSHIP BLOCK
    .internship {
      .input-block {
        padding-top: 0;
        margin-right: 20px;
      }
    
      .blue-remuneration-list {
        margin-top: 30px;
      }
    }

    .interns, .tutors {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      p {
        margin-right: 1rem;
      }
    
      .input-block {
        width: auto;
        margin: 0;
        input {
          margin: 0;
        }
      }
    }
  
    .accepted-years {
      margin-bottom: 25px;
      p {
        margin-bottom: 10px;
      }
      .checkbox, .checkbox-blue {
        margin: 5px 0 5px 2rem ;
      }
    }
  
    .schools {
      p {
        margin-bottom: 20px;
      }
  
      .schools-input{
        position: relative;
        width: 435px;
      }
    
      .schools-list {
        @include base-list;
        width: 433px;
        padding: 0;
        height: 280px;
        margin-top: 25px;
        padding-top: 0;
        scrollbar-width: thin;
        scrollbar-color: $peach white; 
        overflow-y: scroll;  
    
        li {
          display: flex;
          align-items: center;
          padding: 10px 20px;
          &:hover {
            background-color: $peach3;
            cursor: pointer;
          }
          &.not-found {
            color: $grey3;
            cursor: default;
            margin-left: 20px;
            &:hover {
              background-color: #fff;
            }
          }
        }
    
        .checkbox, .checkbox-blue {
          .square {
            height: 20px;
            min-width: 20px;
            border-radius: 8px;
            position: relative;
    
            .inner-blue-circle {
              height: 12px;
              width: 12px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          margin-right: .5rem;
        }
      }
    
      .schools-list::-webkit-scrollbar {
        width: 0.8em;
      }
    
      .schools-list::-webkit-scrollbar-thumb {
        background-color: $peach;
        border-radius: 10px;
      }
    }
  
  
    .all-confirmed-schools {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 750px;
      margin-bottom: 10px;
    }
    
    .confirmed-school {
      @include base-confirmed;
      width: 120px;
  
      div {
        display: flex;
        align-items: center;
  
        p {
          width: calc(120px - 35px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }  
    }
  }

}

@media only screen and (max-width: 1024px) {
  .create-mission .slide-two .content {
    width: 550px;
  }
}

@media only screen and (max-width: 900px) {
  .create-mission .slide-two .content {
    width: 450px;
  }
}