.subscription-pending {
  min-height: 60px;
  max-height: 350px;
  width: 197px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid $light-grey;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: scroll;

  h1.header {
    font-size: 12px;
    color: white;
    background-color: $orange;
    padding: 5px;
    border-radius: 3px;
    text-align: center;
    font-family: $light-font;

    &.light {
      background-color: white;
      color: $grey;
      font-family: $main-font;
    }
  }

  .subscriptions {
    margin-top: 20px;

    .subscription {
      margin-bottom: 10px;
      padding: 10px;
      border-bottom: 1px solid $grey3;
      background-color: $grey5;

      .title {
        font-size: 12px;
        text-align: left;
        margin-bottom: 2px;
      }

      .createdAt {
        margin-bottom: 5px;
        font-style: italic;
        font-size: 9px;
      }

      .availability {
        font-size: 10px;
      }

      .createdAt,
      .availability {
        color: $grey2;
      }

      .message {
        font-family: $light-font;
        font-size: 11px;
        line-height: 15px;
        margin: 10px 0;
        color: $grey;
        max-height: 80px;
        border-right: 1px solid $grey3;
        padding-right: 5px;
        overflow: scroll;

        &:before {
          content: '✉️ " ';
        }

        &:after {
          content: ' "';
        }
      }

      .cta {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .accept,
        .refuse,
        .cancel {
          min-width: 85px;
          height: 30px;
          border-radius: 15px;
          font-weight: 700;
          font-size: 11px;
          border: 1px solid black;
          background-color: white;

          &:hover {
            color: white;
          }
        }

        .accept {
          color: $blue;
          border-color: $blue;

          &:hover {
            background-color: $blue;
          }
        }

        .refuse {
          color: $orange;
          border-color: $orange;

          &:hover {
            background-color: $orange;
          }
        }

        .cancel {
          color: $grey;
          border: none;

          &:hover {
            background-color: $grey;
          }
        }

        .forbidden {
          color: $grey;
          border: none;

          &:hover {
            background-color: $grey;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
