.pricing {
  position: relative;
  background-color: $grey5;
  z-index: 0;

  .orange-background {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: -1;
  }

  .green-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 20%;
    z-index: -1;
  }

  .content {
    z-index: 1;
    padding-top: 200px;
    min-height: calc(100vh + 120px);
    display: flex;
    flex-direction: column;
    align-items: center;

    .plans {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .conditions {
      font-size: 12px;
      width: 750px;
      padding: 10px 0;

      > div {
        margin-bottom: 7px;
        color: $grey2;

        em {
          font-size: 10px;
          margin-right: 5px;
        }
      }
    }
  }
}
