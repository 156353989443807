@mixin search-input($color: $peach) {
  @if ($color != $orange) {
  }
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 1px solid $color;
  padding: 0 25px;
  caret-color: $color;
  font-family: $main-font;
  color: $grey;

  &::placeholder {
    font-family: $main-font;
    color: $grey3;
  }
}

@mixin selector($color: $peach) {
  z-index: 3;
  font-size: 30px;
  color: $color;
  cursor: pointer;
  margin: 0 0 10px 0;
  position: absolute;
  right: 20px;
  bottom: 0px;
}

.content {
  .orange-input {
    @include search-input();
  }

  .error-input {
    @include search-input($orange);
  }

  .blue-input {
    @include search-input($blue);
  }

  .blue-check {
    color: $blue;
    position: absolute;
    right: 20px;
    bottom: 0px;
  }

  .input-block {
    position: relative;
    width: 435px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .input-label {
      font-size: 13px;
      position: absolute;
      background-color: white;
      top: -10px;
      left: 20px;
      padding: 0 5px;
      transition: all 0.3s;
      z-index: 10;

      &.hidden {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 20px;
        font-family: $main-font;
        font-size: 16px;
        color: $grey3;
      }

      &.blue-label {
        color: $blue;
      }

      &.error-label {
        color: $orange;
      }

      &.orange-label {
        color: $peach;
      }
    }

    &.open {
      input {
        border-radius: 20px 20px 0 0;
        border-bottom: hidden;
      }
    }
  }

  .error-selector {
    @include selector($orange);
  }

  .orange-selector {
    @include selector();
  }

  .blue-selector {
    @include selector($blue);
    z-index: 10;
    font-size: 30px;
    margin: 0 0 10px -0;
    bottom: 0px;
  }
}
