.toggle {
  background-color: $main-green;
  display: flex;
  align-items: center;
  width: 60px;
  color: white;
  height: 30px;
  border-radius: 25px;
  cursor: pointer;
  position: relative;
  animation: toggleOn 0.25s ease-out;
  
  .white-circle {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    background-color: white;
    position: absolute;
    right: 4px;
  }

  &.disabled {
  background-color: $grey3;
  animation: toggleOff 0.25s ease-out;

  
  .white-circle {
      left: 4px;
    }
  }
}

@keyframes toggleOn {
  0% {
    background: $grey3;
    .white-circle {
      right: 4px;
    }
  }
  30% {
    .white-circle {
      height: 16px;
      width: 16px;
      right: 0px;
      left: 0px;
    }
  }
  100% {
    background: $main-green;
    .white-circle {
      left: 4px;
    }
  }
}

@keyframes toggleOff {
  0% {
    background: $main-green;
    .white-circle {
      left: 4px;
    }
  }
  15% {
    .white-circle {
      height: 16px;
      width: 16px;
      right: 0px;
      left: 0px;
    }
  }
  100% {
    background: $grey3;
    .white-circle {
      right: 4px;
    }
  }
}