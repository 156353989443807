.validate-blue {
  width: 220px;
  height: 60px;
  border-radius: 37.5px;
  background-color: white;
  color: $blue;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  border: 3px solid $blue;

  &.small {
    min-width: 200px;
    width: auto;
    height: 30px;
    border-radius: 15px;
    font-weight: 700;
    font-size: 14px;
    border: 2px solid $blue;
    margin: 8px 0 8px 20px;
  }
}

.validate-blue:hover,
.validate-blue:active,
.validate-blue:focus {
  border: 3px solid transparent !important;
  background-color: $blue !important;
  color: white;
}

.validate-reverse {
  width: 220px;
  height: 60px;
  border-radius: 37.5px;
  background-color: $blue;
  color: white;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  border: 3px solid white;
}

.validate-reverse:hover {
  background-color: white;
  border: 3px solid $blue;
  color: $blue;
}

.validate-white {
  width: 220px;
  height: 60px;
  border-radius: 37.5px;
  background-color: white;
  color: $light-grey;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  border: 3px solid $light-grey;
  cursor: default;
}

.validate-red {
  width: 220px;
  height: 60px;
  border-radius: 37.5px;
  background-color: $orange;
  color: white;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  border: 3px solid $orange;
  position: absolute;
  top: 800px;
  left: 350px;
  margin-bottom: 50px;
}

.validate-red-white {
  width: 220px;
  height: 60px;
  border-radius: 37.5px;
  background-color: white;
  color: $orange;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  border: 3px solid $orange;
  /* position: absolute; */
  /* top: 800px; */
  /* left: 350px; */
  /* margin-bottom: 50px; */
}

.validate-red-white:hover,
.validate-red-white:active {
  border: 3px solid transparent;
  background-color: $orange;
  color: white;
}

.validate-orange {
  width: 220px;
  height: 60px;
  border-radius: 37.5px;
  background-color: white;
  color: $light-grey;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  border: 3px solid $peach;
  position: absolute;
  top: 800px;
  left: 350px;
  margin-bottom: 50px;
}

.validate-transparent {
  width: 220px;
  height: 60px;
  border-radius: 37.5px;
  background-color: white;
  color: $light-grey;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  border: 3px solid transparent;
  position: static;
  margin: 90px 0 40px 0;
}

.validate-transparent:hover {
  color: $orange;
  opacity: 0.5;
}

.validate-green {
  min-width: 220px;
  height: 60px;
  border-radius: 37.5px;
  background-color: $main-green;
  color: white;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  border: 3px solid $main-green;
}

.validate-disabled {
  width: 220px;
  height: 60px;
  border-radius: 37.5px;
  background-color: $grey2;
  color: $grey4;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: 3px solid $grey2;
  text-align: center;

  &:hover {
    cursor: wait;
  }

  &.forbidden:hover {
    cursor: not-allowed;
  }
}
