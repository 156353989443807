.substitute-thumbnail {
  width: 230px;
  padding: 20px 3px;
  background-color: white;
  // height: 380px;
  margin: 0 30px 30px 0;
  border-radius: 10px;
  border: 1px solid $light-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  cursor: pointer;
  position: relative;
  display: inline-flex;

  .profession {
    &.ghost {
      width: 10rem;
      background-color: #eee;
      &::after {
        box-shadow: none;
      }
    }
    display: flex;
    position: relative;
    align-items: center;
    background-color: #898d92;
    margin-bottom: 10px;
    padding: 3px;
    border-radius: 15px;
    height: 17px;
    color: white;
    width: max-content;
    font-size: 16px;

    &.empty {
      background-color: white;
    }

    p {
      margin: 0 10px 0 30px;
      font-size: 11px;
    }

    .profession-icon {
      height: 17px;
      width: 17px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      padding: 3px;
      border-radius: 15px;
      background-color: #6d7278;
    }
  }
}

.substitute-thumbnail:hover {
  box-shadow: $grey-shadow;
}

.substitute-thumbnail .avatar {
  height: 128px;
  width: 128px;
  border-radius: 100%;
  overflow: hidden;
  margin: 20px 0;
  background-color: $light-grey;

  // ↓↓ W/ BORDER ↓↓
  // box-sizing: border-box;
  // -moz-box-sizing: border-box;
  // -webkit-box-sizing: border-box;

  // &.available {
  //   border: solid 3px $main-green;
  // }
}

.substitute-thumbnail .avatar img {
  width: 100%;
}

.substitute-thumbnail .graduation {
  display: flex;
  align-items: center;
}

.substitute-thumbnail .graduation-country {
  display: flex;
}

.substitute-thumbnail .graduation-year {
  font-size: 10px;
}

.substitute-thumbnail .skills {
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  border-top: 1px solid $peach;
  border-bottom: 1px solid $peach;
  padding: 15px 0;
  margin: 10px 0;
}

.substitute-thumbnail .no-skills {
  height: 74px;
}

.substitute-thumbnail .skill {
  border: 1px solid $blue;
  min-width: 80px;
  padding: 5px 10px;
  text-align: center;
  margin: 0 5px;
  border-radius: 15px;
}

.substitute-thumbnail .skill-popup {
  border: 1px solid $blue;
  border: 1px solid $blue;
  padding: 5px 10px;
  text-align: center;
  margin: 0 5px;
  border-radius: 15px;
  position: relative;
}

.substitute-thumbnail .is-favorite {
  color: $peach;
  font-size: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.substitute-thumbnail .holder {
  display: flex;
  align-items: center;
  color: $grey;
  i {
    margin-right: 0.5rem;
  }
}

.substitute-thumbnail .city {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey;
  font-size: 14px;
  margin: 10px 0 20px 0;
  height: 15px;
  width: 80%;
  p {
    text-align: center;
  }
  i {
    margin-right: 0.5rem;
  }
}

.substitute-thumbnail .validate-white,
.substitute-thumbnail .validate-blue {
  position: static;
  width: 200px;
  font-size: 12px;
  height: 30px;
  margin: 0;
}

.substitute-thumbnail .validate-white {
  color: $blue;
}

.substitute-thumbnail .highlighted-tip {
  top: 300px;
}
