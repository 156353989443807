.authentification {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 220px;

  &.content {
    .input-block {
      margin-bottom: 30px;
    }
  }
}

.authentification .top-logo {
  position: absolute;
  width: 150px;
  top: 50px;
  left: 100px;
}

.authentification .glad-to-see-you {
  max-width: 800px;
  font-size: 50px;
  color: $main-green;
  margin-bottom: 10px;
}

.authentification .green-text {
  color: $main-green;
  margin: 20px 0 60px 0;
}

.authentification .subscribe {
  font-size: 14px;
  margin-top: 80px;
}

.authentification .redirect-green {
  width: 300px;
  height: 60px;
  background-color: $main-green;
  border-radius: 37.5px;
  color: white;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  margin: 20px 0 20px 0;
}

.authentification .reset {
  cursor: pointer;
}

.authentification .invalid {
  font-size: 14px;
  color: $orange;
  height: 14px;
  text-align: center;
  line-height: 18px;
  margin-bottom: 20px;
}
