.discussion-thumbail {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: end;
  height: 120px;
  padding: 10px;
  box-sizing: border-box;
  font-family: $light-font;
  border-left: 8px solid $grey4;
  border-bottom: 1px solid $grey4;

  &:hover {
    cursor: pointer;
    background-color: $grey4;
    border-left: 8px solid $grey;
  }

  &.new-message {
    font-family: $main-font;
    background-color: $peach2;
    border-left: 8px solid $peach;

    .last-update-timestamp {
      font-weight: bold;
      &:after {
        content: " 🔔";
      }
    }

    .last-message-preview {
      margin-top: 10px;
      font-size: 10px;
      font-weight: bold;
      &:before {
        content: "📪";
        font-size: 15px;
        margin-right: 8px;
      }
    }
  }

  &.selected {
    border-left: 8px solid $blue;
    background-color: $blue3;
  }

  .last-update-timestamp {
    font-size: 9px;
    font-style: italic;
    text-align: end;
    position: absolute;
    top: 10px;
    right: 10px;
    color: $grey2;
    padding: 3px 6px;
    border-radius: 2px;
  }

  .row-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    .isActive {
      position: absolute;
      left: 40px;
      bottom: -2px;
      display: inline-block;
      min-width: 13px;
      min-height: 13px;
      border-radius: 100%;
      margin: 0 5px;
      background-color: $main-green;
    }
  }

  .profile-picture {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    margin-right: 10px;
  }

  .firstname,
  .last-message-preview {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
    line-height: 18px;
  }

  .firstname {
    font-size: 16px;
  }

  .last-message-preview {
    margin-top: 10px;
    font-size: 10px;
  }

  .status {
    position: absolute;
    bottom: 10px;
    right: 10px;
    border-radius: 5px;
    font-size: 10px;
    text-align: right;
    padding: 6px 10px;

    &.new-message {
      background-color: $peach;
      color: black;
    }

    &.pending-candidate {
      background-color: $orange;
      color: white;
    }

    &.waiting-for-candidate {
      background-color: $grey4;
    }
  }
}
