.list-adsthumbnail {
  .pagination {
    margin-top: 0;
  }

  .pagination-ads {
    li {
      &.previous,
      &.next {
        & a {
          color: #29b6e5;
        }
      }

      &.active {
        background-color: #29b6e5;
        border-color: #29b6e5;
      }
    }
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 150px;
  }
}
