.substitute-thumbnail .availability {
  color: $orange;
  font-size: 10px;
  margin-bottom: 10px;
  border: 1px solid $orange;
  padding: 3px 5px;
  border-radius: 5px;
  font-weight: bold;
  background-color: $orange3;
}
