.ListSelect {
  position: relative;
  margin-bottom: 100px;

  .value {
    padding-left: 25px;
    width: 80%;
  }

  &__triangle {
    position: absolute;
    top: 5px;
    right: 1rem;
    color: $peach;
    font-size: 30px;
  }

  &__container {
    background-color: white;
    border-radius: 20px;
    border: solid 1px $peach;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: inherit;
    position: absolute;
    cursor: pointer;

    .placeholder {
      color: $grey3;
      font-size: 14px;
      padding-left: 25px;
    }

    span {
      padding: 12px;
      display: flex;
      align-items: center;
    }
  }

  &__list {
    max-height: 280px;
    overflow-x: hidden;
    background-color: white;
    border-radius: 0 0 20px 20px;
    margin-top: 0;
    border-top: none;
    scrollbar-width: thin;
    scrollbar-color: $blue white;

    &__item {
      padding: 12px;
      padding-left: 25px;
      line-height: 15px;

      &:hover {
        color: $blue;
      }

      &:last-child {
        border-radius: 0 0 20px 20px;
      }

      &.disabled {
        color: grey;
        margin-left: 5px;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }

  &.error {
    .ListSelect__triangle {
      color: $orange;
    }

    .ListSelect__container {
      border: solid 1px $orange;
    }
  }

  &.success {
    .ListSelect__triangle {
      color: $blue;
    }

    .ListSelect__container {
      border: solid 1px $blue;
    }
  }

  &__label {
    font-size: 13px;
    position: absolute;
    background-color: white;
    top: -10px;
    left: 20px;
    padding: 0 5px;
    transition: all 0.3s;
    z-index: 1;

    &.hidden {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 20px;
      font-family: $main-font;
      font-size: 16px;
      color: $grey3;
    }

    &.blue-label {
      color: $blue;
    }

    &.error-label {
      color: $orange;
    }

    &.orange-label {
      color: $peach;
    }
  }
}

.ListSelect__list::-webkit-scrollbar {
  width: 0.8em;
}

.ListSelect__list::-webkit-scrollbar-thumb {
  background-color: $blue;
  border-radius: 10px;
}
