.button .green {
  width: 300px;
  height: 60px;
  background-color: $main-green;
  border-radius: 37.5px;
  color: white;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  margin: 50px 0 20px 0;
}

.button .blue {
  width: 220px;
  height: 60px;
  border-radius: 37.5px;
  background-color: $blue;
  color: white;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  border: 3px solid transparent;
}

.button .transparent {
  width: 300px;
  height: 60px;
  border-radius: 37.5px;
  color: $light-grey;
  background-color: white;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  border: 3px solid $blue;
}

.button .transparent-blue {
  width: 200px;
  height: 50px;
  border-radius: 37.5px;
  color: $blue;
  background-color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  outline: none;
  border: none;
  border: 3px solid $blue;
}

.button .validate-green {
  min-width: 200px;
  height: 30px;
  border-radius: 15px;
  background-color: $main-green;
  color: white;
  font-weight: 700;
  font-size: 14px;
  border: none;
  margin: 8px 0 8px 20px;
}

.button .forbidden.small{
  min-width: 200px;
  height: 30px;
  border-radius: 15px;
  background-color: $grey3;
  color: $grey;
  font-weight: 700;
  font-size: 14px;
  outline: none;
  border: none;
  margin: 8px 0 8px 20px;

  &:hover {
    cursor: not-allowed;
  }
}
