.discussions-infos {
  min-height: 200px;
  display: flex;
  flex-direction: column;

  &.hide-title {
    border-top: 1px solid $grey4;
    padding-top: 20px;
    min-height: 30px;
  }
  span:first-child {
    margin-right: 10px;
  }

  h2 {
    display: flex;
    align-items: center;
  }

  .new-feature {
    line-height: 20px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    border-radius: 5px;
    background-color: $grey3;
    color: $grey;
    margin-left: 20px;
  }

  .light-text {
    margin-top: 20px;
    margin-bottom: 35px;
  }

  .call-to-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      margin-top: 15px;
    }
    span {
      margin: 0;
      i {
        margin: 8px;
      }
      &:hover {
        color: $blue;
        cursor: pointer;
      }
    }
  }

  .modal {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    top: 0;
    left: 0;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*
      /!\ backdrop-filter not supported by Firefox
    */
    backdrop-filter: blur(15px);
    @include firefox-only {
      background-color: $peach3;
    }

    .message-input-form {
      margin-top: 20px;
      position: static;
      width: 100%;
      height: 100%;

      textarea {
        height: 100%;
        &::placeholder {
          color: $grey3;
        }
      }
    }

    i {
      position: absolute;
      top: 20px;
      right: 20px;
      &:hover {
        color: $blue;
        cursor: pointer;
      }
    }
  }
}
