.candidates-page .candidate {
  display: flex;
  position: relative;
}

.candidates-page .candidate {
  margin-bottom: 30px;
  padding: 50px;
  border: 1px solid $light-grey;
  border-radius: 25px;
  cursor: pointer;
  width: 600px;
}

.candidates-page .candidate:hover {
  box-shadow: $grey-shadow;
  border: 1px solid transparent;
}

.candidates-page .candidate .avatar {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 50px;
}

.candidates-page .candidate .avatar img {
  height: 100%;
}

.candidates-page .candidate .avatar i {
  font-size: 105px;
  color: $light-grey;
}

.candidates-page .candidate .dates {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.candidates-page .candidate .dates i {
  margin-right: 10px;
  font-size: 24px;
  color: $peach;
}

.candidates-page .candidate .name {
  font-size: 32px;
  margin-bottom: 10px;
}

.candidates-page .candidate .introduction-text {
  font-size: 12px;
  width: 350px;
  text-align: justify;
  white-space: pre-wrap;
}

.candidates-page .validate-white,
.candidates-page .validate-blue,
.candidates-page .validate-red,
.candidates-page .validate-red-white {
  position: static;
  margin-right: 50px;
}

.candidates-page .candidate-block {
  width: 700px;
  margin-bottom: 30px;

  .button-loader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 220px;

    .loader {
      position: absolute;
      right: 10px;
    }
  }
}

.candidates-page .buttons {
  display: flex;
  justify-content: flex-start;
}

.candidates-page .validate-white {
  color: $blue;
}

.candidates-page .candidate .refused-status {
  position: absolute;
  top: 20px;
  right: 40px;
  padding: 5px 20px;
  background-color: $orange;
  border-radius: 15px;
  color: white;
}

.candidates-page .candidate .accepted-status {
  position: absolute;
  top: 20px;
  right: 40px;
  padding: 5px 20px;
  background-color: $main-green;
  border-radius: 15px;
  color: white;
}

.candidates-page .candidate .canceled-status {
  position: absolute;
  top: 20px;
  right: 40px;
  padding: 5px 20px;
  background-color: $grey3;
  border-radius: 15px;
  color: $grey;
}

.candidates-page .candidate .avatar img.profile-pic {
  border-radius: 50px;
}

.candidates-page .contact {
  top: 30px;
  font-size: 12px;
  height: 50px;
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
}

.candidates-page .contact .email {
  margin-right: 20px;
  cursor: pointer;
}

.candidates-page .contact .email,
.candidates-page .contact .tel {
  display: flex;
  align-items: center;
  border: 1px solid $blue;
  border-radius: 30px;
  margin-left: -1px;

  &.disabled {
    border: 1px solid $grey3;
    color: $grey3;
    > div:nth-child(1) {
      background-color: $grey3;
    }
  }
}

.candidates-page .contact .email > div:nth-child(1),
.candidates-page .contact .tel > div:nth-child(1) {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.candidates-page .contact i {
  color: white;
  font-size: 25px;
}

.candidates-page .contact .text {
  margin: 0 20px 0 10px;
}

.candidates-page {
  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
