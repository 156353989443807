.small-screens {
  display: none;
}

@media only screen and (max-width: 767px) {
  .responsive-app {
    display: none;
  }

  .small-screens {
    display: block;
  }
}

@mixin firefox-only {
  @at-root {
    @-moz-document url-prefix() {
      & {
        @content;
      }
    }
  }
}
