.create-mission .slide-four .content {
  padding-top: 25vh;
}

.create-mission .slide-four .content .header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.create-mission .slide-four .buttons-redirect {
  margin-top: 60px;
  display: flex;
  :not(button:first-child) {
    margin-left: 50px;
  }
}

.create-mission .slide-four .validate-blue {
  position: static;
  width: 250px;
  font-size: 18px;
  line-height: 1;
}

.create-mission .slide-four .validate-grey {
  border: 3px solid $blue;
  color: $blue;
  &:hover {
    background-color: $blue;
    color: white;
  }
}

.create-mission .slide-four h2 {
  font-size: 54px;
  color: $main-green;
  margin-bottom: 40px;
}

.create-mission .slide-four .text {
  font-size: 18px;
  max-width: 600px;
  opacity: 1;
  transition: opacity 0.2s ease;
  will-change: opacity;
}
.create-mission .slide-four .text.hidden {
  opacity: 0;
}

.create-mission .slide-four .envelop {
  width: 100px;
  height: auto;
  margin-right: 2.5rem;
}

.create-mission .slide-four {
  position: relative;
}

.create-mission .slide-four .right-leaves {
  position: absolute;
  height: 100vh;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 900px) {
  .create-mission .slide-four .right-leaves {
    display: none;
  }
  .create-mission .slide-four .content {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .create-mission .slide-four .text {
    text-align: center;
  }
}
