.substitute-alert {
  display: flex;
  padding: 5px 10px 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  border: 1px solid white;

  &:hover {
    box-shadow: 0 0 1px lightgrey;
  }

}

.substitute-alert > div {
  flex: 1;
  font-size: 16px;
  text-align: center;
}

.substitute-alert .validate-blue {
  position: static;
  height: 50px;
  font-size: 18px;
  margin: 0 0 0 30px;
}

@media only screen and (max-width: 900px) {
  .substitute-alert .validate-blue {
    font-size: 14px;
    width: 150px;
    height: 40px;
  }
  .substitute-alert > div {
    font-size: 16px;
  }
}

.alert-date-divider {
  display: flex;
  justify-content: center;
  border-bottom: 1px dashed #979797;
  height: 17px;
  margin: 10px;
}

.alert-date-divider span {
  background-color: white;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
  color: $main-green;
}

.date-old span {
  color: #979797;
}

.date-very-old span {
  color: red;
}

.alert-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alert-zones {
  font-family: $main-font;
  display: flex;
  white-space: pre;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 2px;
}

.alert-zone {
  font-size: 14px;
  font-weight: 600;
  padding: 7px 12px;
  border-radius: 15px;
  margin: 4px;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.zone-far {
  border: 2px solid $orange;
  color: $orange;
}

.zone-near {
  border: 2px solid $main-green;
  color: $main-green;
}
