.home {
  background-color: $grey5;
  height: 100vh;
  padding-top: 160px;
}

.home .container {
  max-width: 1200px;
  margin: 0 auto;
  height: 500px;
  background-color: white;
  border-radius: 20px;
}
