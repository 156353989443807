.office-card {
  font-weight: 100;
  border: solid 1px $grey4;
  padding: 20px;
  margin-top: 60px;
  border-radius: 5px;
  position: relative;
  width: 500px;
  line-height: 20px;
  font-size: 14px;
  transition: all 0.5s ease;

  .office-name {
    position: absolute;
    top: -31px;
    right: 5px;
    background-color: $peach;
    padding: 5px;
    border-radius: 5px 5px 0 0;
    color: white;
  }

  &:hover {
    cursor: pointer;
    border-color: $peach;

    &:not(.selected) {
      box-shadow: 0 0 10px $peach;
    }
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;

    .item-label {
      color: $grey;
    }

    .item-value {
      color: $grey2;
      text-align: end;
    }
  }

  &.selected {
    display: flex;
    flex-direction: column;
    border-color: $blue;

    .office-name {
      background-color: $blue;
    }

    .item {
      .item-label {
        color: $blue;
      }
    }

    .validate-blue {
      height: auto;
      width: auto;
      font-size: 15px;
      border: 3px solid $blue2;
      padding: 5px 20px;
      margin-top: 10px;

      &:hover {
        margin-top: 10px;
        border: 3px solid transparent;
      }
    }
  }
}
