.substitute-thumbnail {
  &.ghost {
    &:hover {
      box-shadow: none;
      cursor: default;
    }
    .pertinence {
      position: absolute;
      left: 20px;
      top: 20px;
      background-color: #fff;
    }
    
    i.is-favorite {
      color: #eee;
    }

    .skills {
      border-color: #eee;
    }
  }
}