.reset-password .slide-two .text {
  margin-bottom: 40px;
  margin-top: 30px;
  max-width: 600px;
  text-align: center;
  font-size: 15px;
  color: $main-green;
  line-height: 28px;
  border-left: 5px $main-green solid;
  background-color: $grey5;
  padding: 15px;
}

.reset-password .slide-two {
  margin: 250px auto 0 auto;
}

.reset-password .slide-two .reset-title {
  font-size: 40px;
  margin-bottom: 30px;
  color: $main-green;
}
