.error-page {
  height: 100vh;
  width: 100vw;
  background-color: $main-green;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-page .error-block {
  padding: 100px 0;
  width: 1200px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.error-page .validate-blue {
  position: static;
  width: 300px;
  margin: 50px 0 0 0;
}

.error-page h2 {
  font-size: 54px;
  margin-bottom: 50px;
}

.error-block > div {
  font-size: 18px;
  margin-bottom: 5px;
}

.error-block .palm-tree {
  position: absolute;
  height: 700px;
  right: -150px;
  top: -50px;
  z-index: 2;
}

.error-block .green-leaves {
  position: absolute;
  height: 250px;
  left: 0;
  bottom: 50px;
}

.error-block .brown-stone {
  position: absolute;
  right: 0;
  height: 250px;
  bottom: -200px;
  z-index: 0;
}
