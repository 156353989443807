.orange-multiple-input {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid $peach;
  padding: 20px 25px;
  caret-color: $peach;
  cursor: pointer;
  resize: none;
}

.blue-multiple-input {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid $blue;
  padding: 20px 25px;
  caret-color: $blue;
  cursor: pointer;
  resize: none;
}

/*  .blue-check {
    color: $blue;
    position: absolute;
    right: 20px;
    bottom: 0px;
  }
   */
.multiple-input-block {
  position: relative;
  width: 435px;
  height: 150px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.multiple-input-block :disabled {
  background-color: #ececec !important;
}