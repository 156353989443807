
.slide-three {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.slide-three .all-roles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 800px;
}
