// * Main Block
.my-candidates-page .no-candidates {
  margin-top: 50px; 
  color: #e6e6e6;
  font-size: 22px;
  text-align: center;
}

.vl {
  padding-left: 30px;
  border-left: 1px solid $grey3;
}

// * My Candidates Thumbnail
.candidates_thumbnail {
  &.ghost {
    cursor: default;
    &:hover {
      box-shadow: none;
    }
  }

  .holder-badge {
    background-color: $grey4;
    padding: .2rem .5rem;
    font-size: 11px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-left: .2rem;
    i {
      margin-right: .4rem;
    }
  }

  .skills {
    font-size: 12px;
    display: flex;
    align-items: center;
  
    i {
      color: $peach;
      font-size: 14px;
      margin-right: 10px;
      margin-left: 1px;
    }
  
    .no-skills {
      height: 74px;
      width: 170px;
    }
    
    .skill {
      border: 1px solid $peach;
      min-width: 80px;
      padding: 5px 10px;
      text-align: center;
      margin: 0 5px;
      border-radius: 7px;
  
      &-popup {
        border: 1px solid $peach;
        padding: 5px 10px;
        text-align: center;
        margin: 0 5px;
        border-radius: 7px;
        position: relative;
      }
    }
  
    .no-skill{
      padding-left: 2px;
      font-size: 16px;
      color: $grey3;
    }
  }
  
  .contact-infos {
    margin-top: 40px;
    grid-area: contact;
    padding-top: 20px;
    hr {
      background-color: $grey5;
      border: 1px solid $grey5;
    }

    &.ghost {
      margin-top: 20px;
      display: flex;
      justify-content: space-evenly;
      hr {
        background-color: $grey5;
        border: 1px solid $grey5;
      }
    }
    
    &-details {
      margin-top: 20px;
      display: flex;
      justify-content: space-evenly;
    }
  }
  
  .profession {
    &.ghost {
      width: 10rem;
      background-color: #eee;
      &::after{
        box-shadow: none;
      }
    }
    display: flex;
    position: relative;
    align-items: center;
    background-color: #898D92;
    padding: 5px;
    border-radius: 15px;
    height: 17px;
    color: white;
    width: max-content;
    font-size: 16px;
    bottom: 24px;
  
    p {
      margin: 0 10px 0 30px;
      font-size: 14px;
    }
  
    .profession-icon {
      height: 17px;
      width: 17px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      padding: 5px;
      border-radius: 15px;
      background-color: #6D7278;
    }
  }
  
  .star {
    grid-area: star;
    font-size: 30px!important;

    &.ghost {
      cursor: default;
    }
    
    .ghost {
      color: #eeeeee;
      font-weight: 500;
    }
    .is-favorite {
      color: $peach;
      &:hover {
        color: #da9c56;
      }
    }
    .is-not-favorite {
      color: $peach;
      font-weight: 500;
      &:hover {
        color: #e4cbaf;
        font-weight: 900;
      }
    }
    display: flex; 
    align-items: center; 
    justify-content: flex-end;
    cursor: pointer;
    position: relative;
    top: 10px;
  }
  
  .content {
    grid-area: content;
  
    .line1 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
  
      h4 {
        margin: 0;
        margin-right: 10px;
      }
    
      .blue-circle {
        height: 10px;
        width: 10px;
        border-radius: 5px;
        background-color: $main-green;
        margin-right: 5px;
      }
        
      .pertinence {
        position: relative;
        border-width: 0;
        margin-right: 50px;
        left: 0;
        top: 0;
      }
    }
  
    .line2 {
      padding: 20px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
  
      img {
        margin-right: 10px;
      }
      
      i {
        color: $peach;
        margin-right: 10px;
      }

      .no-graduation {
        color: $light-grey;
      }
    }
  
    .line3 {
      text-align: center;
      margin-top: 5px;
      .is-locked {
        color: $orange;
      }

      &.ghost {
        text-align: start;
      }
    }
  }
  
  .avatar {
    grid-area: avatar;
    height: 130px;
  
    img {
      border-radius: 100%;
      height: 130px;
      width: 130px;
    }
  
    .is-available {
      color: $main-green;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    
      position: relative;
      bottom: 18px;
      margin: 0 auto;
      padding: 5px 0;
      background-color: #fff;
      border-radius: 14px;
      box-shadow: 0 0 8px 2px hsla(0,0%,39.2%,.1);
      width: 90%;
    
      .blue-circle {
        height: 9px;
        width: 9px;
        border-radius: 5px;
        background-color: $main-green;
        margin-right: 5px;
      }
    }
  }
  
  .caption {
    grid-area: caption;
    align-self: flex-end;
    justify-self: end;
    font-size: 12px;
    color: $grey3;
    text-align: right;
    bottom: -20px;
    white-space: nowrap;
    position: absolute;
  }

  .no-graduation {
    color: $grey3!important;
    font-family: $main-font;
  }

  border: 1px solid $light-grey;
  padding: 10px 25px 30px 25px;
  margin-top: 44px;
  border-radius: 20px;
  // display: flex;
  position: relative;
  background-color: white;
  width: 600px;
  z-index: 1;
  
  
  display: grid;
  grid-template-columns: 130px auto;
  grid-template-rows: auto;
  // row-gap: 10px;
  column-gap: 30px;
  grid-template-areas: 
  ". . . star"
  "avatar content content caption"
  "contact contact contact contact";
  cursor: pointer;
  
  &:hover {
    .profession::after {
      position: absolute;
      content: '';
      width: 95%;
      height: 50%;
      top: 0;
      left:3%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      box-shadow: 0 0 5px 5px hsla(0,0%,39.2%,.1);
      z-index: -1;
    }
    .profession.ghost::after {
      box-shadow: none;
    }
    box-shadow: 0 0 5px 5px hsla(0,0%,39.2%,.1);
  }
}

// * Pagination
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.pagination li {
  display: inline-block;
  height: 17px;
  width: 17px;
  margin: 5px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;

  & a {
    color: #d8d8d8;
  }

  &.previous {
    width: auto;
    margin-right: 15px;

    & a {
      color: $main-green;
    }
  }

  &.next {
    width: auto;
    margin-left: 15px;

    & a {
      color: $main-green;
    }
  }

  &.active {
    padding: 5px;
    border: 2px solid $main-green;
    background-color: $main-green;
    border-radius: 100%;
    cursor: default;

    & a {
      color: white;
    }
  }
}

// * Filter Block
.my-candidates-page .filter-block {
  // Filter Block → left side)
  @media (min-width: 1900px) {
    width: 230px;
    margin: 0;
    padding: 20px 40px;
    max-height: 50%;
    background-color: white;
    border-radius: 20px;
    position: fixed;
    top: 300px;
    left: 5vw;
    text-align: center;
  
    h3 {
      margin: 20px 0;
    }
  
    h4 {
      display: flex;
      justify-content: flex-start;
      margin: 20px 0 10px;
    }
  
    .select-temp {
      padding: 10px;
      border: 1px solid $light-grey;
      color: $grey;
      border-radius: 25px;
      text-align: center;
      margin: 5px 0 15px 0;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.breakline {
        margin-bottom: 30px;
      }
    }
  
    .checkbox {
      margin-bottom: 25px;
      
      &-blue {
        margin-bottom: 25px;
      }
    }
  
    .description {
      font-size: 14px;
      width: 200px;
    }
  }

  // Filter Block → inside container
  @media (max-width: 1900px) {
    display: flex;

    // While filter's not ready
    display: none;

    flex-wrap: wrap;
    margin-top: 30px;

    background-color: #fdfdfd;

    border: 1px solid rgba(100, 100, 100, 0.1);
    border-radius: 20px;
    padding: 5px 20px;

    h3 {
      width:100%;
      margin: 0;
      color: #dad8d8;
      font-size: 21px;
    }

    .select-temp {
      width: 220px;
      padding: 10px;
      border: 1px solid $light-grey;
      color: $grey;
      border-radius: 25px;
      text-align: center;
      margin: 5px 20px 15px 0;
      
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }    

    .hide-tablet {
      display: none;
    }

    .line {
      width: 100%;
      &-1 {
        width: 600px;
        display: flex;
      }
      &-2 {
        width: 600px;
        display: flex;
        flex-wrap: wrap;

        .checkbox {
          margin-bottom: 15px;
          
          &-blue {
            margin-bottom: 15px;
          }
        }
      }

      // .select-temp {
      //   margin:0;
      // }
    }
  }


  // While filter's not ready
  display: none;
}

// * Loading
.main-block {
  .loading {
    display: flex;
    justify-content: center;
    & svg {
      width: 70px;
      height: 70px;
    }
    & span {
      margin: 2rem;
      font-size: 28px;
      color: $main-green;
    }
  }
}

// * Base page
.my-candidates-page {
  background-color: $grey5;
  padding: 170px 0 200px 0;
}

.my-candidates-page .container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 70px;
  min-height: 650px;
  background-color: white;
  border-radius: 20px;
  position: relative;
}


.my-candidates-page h2 {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 17px;
}

.my-candidates-page h3 {
  font-size: 28px;
  line-height: 32px;
  margin: 60px 0 10px 0;
}

.my-candidates-page h4 {
  font-size: 21px;
  line-height: 24px;
  margin-right: 20px;
  margin-bottom: 30px;
}

.my-candidates-page .establishment-name {
  font-size: 16px;
  border: 1px solid $light-grey;
  padding: 10px 30px;
  border-radius: 25px;
  margin-bottom: 30px;
  max-width: 200px;

  // ellipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.my-candidates-page .status {
  display: flex;
}

.my-candidates-page .status li {
  margin-right: 30px;
  font-size: 14px;
  cursor: pointer;
}

.my-candidates-page .blue-link {
  color: $blue;
}

.my-candidates-page .office-name {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .my-candidates-page {
    padding: 0;
  }

  .my-candidates-page .main-block {
    width: calc(100% - 140px);
    border-radius: 0;
    padding-top: 60px;
  }
}
