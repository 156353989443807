.message-input-form {
  position: absolute;
  left: 381px;
  bottom: 0;
  width: calc(100vw - 349px - 330px);
  z-index: 1;
  height: 115px;
  display: flex;
  align-items: center;
  backdrop-filter: blur(15px);

  textarea {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 0 20px;
    border: 1px solid $grey3;
    border-radius: 30px;
    padding: 10px 30px;
    font-family: inherit;
    font-size: 12px;
    height: 100px;
    box-sizing: border-box;

    &:focus {
      border-color: $blue;
    }

    &:disabled {
      background-color: $grey4 !important;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  button {
    background-color: $blue;
    color: white;
    border-radius: 30px;
    padding: 10px 20px;
    border: none;
    margin-right: 20px;

    &:disabled {
      background-color: $grey3;
      color: $grey;
    }

    &.forbidden:disabled {
      &:before {
        content: "🔒 ";
      }

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
