.slide-six {
  margin: 13vh 400px 50px 80px;
}
.slide-six .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 250px auto 0 auto;
}

.slide-six .congrats {
  max-width: 800px;
  text-align: center;
  font-size: 40px;
  color: $main-green;
  margin-bottom: 50px;
}

.slide-six .steps-title {
  max-width: 800px;
  text-align: center;
  font-size: 20px;
  color: $main-green;
  margin-top: 30px;
  margin-bottom: 15px;
}

.slide-six .congrats-text {
  max-width: 800px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 20px;

  span {
    color: $main-green;

    &:hover {
      border-bottom: 2px solid $main-green;
    }
  }

  strong {
    color: $main-green;
  }
}

.slide-six .redirect-green {
  width: 300px;
  height: 60px;
  background-color: $main-green;
  border-radius: 37.5px;
  color: white;
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  outline: none;
  border: none;
  margin-top: 20px;
}
