@mixin base-list($color: $peach) {
    position: absolute;
    height: 280px;
    overflow-x: hidden;
    z-index: 8;
    background-color: white;
    border: 1px solid $color;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: -25px;
    border-top: none;
    scrollbar-width: thin;
    scrollbar-color: $color white;   
}

.list { 
    position: relative;
    width: 435px;
    height: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 3;
    padding-top: 30px;

    &.open {
        input {
            border-radius: 20px 20px 0 0;
            border-bottom: hidden;
            caret-color: transparent;
        }
    }
    
    input {
        caret-color: transparent;
        color: $grey;
        &:hover {
            cursor: pointer;
        }
    }

    &-global {
        width: 435px;
    }
}

.items-list {
    @include base-list;
    width: 433px;
    padding: 10px 0;
    z-index: 2;

    &-success {
        @include base-list($blue);
    }

    li {
        display: flex;
        align-items: center;
        padding: 10px 20px;

        &:hover {
            background-color: $grey5;
            cursor: pointer;
        }

        &.not-found {
        color: $grey3;
        cursor: default;
        margin-left: 20px;
            &:hover {
                background-color: #fff;
            }
        }
    }

    .checkbox, .checkbox-blue {
        .square {
            height: 20px;
            min-width: 20px;
            border-radius: 8px;
            position: relative;

            .inner-blue-circle {
                height: 12px;
                width: 12px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        margin-right: .5rem;
    }
}

.items-list::-webkit-scrollbar {
    width: 0.8em;
}

.items-list::-webkit-scrollbar-thumb {
    background-color: $peach;
    border-radius: 10px;
}

.items-list-success::-webkit-scrollbar-thumb {
    background-color: $blue;
    border-radius: 10px;
}