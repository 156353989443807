.substitute-page .no-ads-container {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.substitute-page .no-ads-container img {
  max-height: 370px;
}

.substitute-page .content {
  background-color: $grey5;
  height: 100%;
  padding: 140px 10px;
}

.substitute-page .content .container {
  display: flex;
  flex-direction: row;
  width: 1400px;
  margin: auto;
}

.substitute-page .introduction {
  position: relative;
}

.substitute-page .introduction,
.substitute-page .bottom-block,
.substitute-page .middle-block,
.substitute-page .administrative-details {
  background-color: white;
  border-radius: 15px;
  margin: 10px;
  padding: 20px;
}

.substitute-page .middle-block {
  position: relative;
  border: 1px solid white;
}

.substitute-page .middle-block.premium-block {
  position: relative;
  border: 1.5px solid $peach;
  background-color: $peach3;
}

.substitute-page .top-block {
  display: flex;
}

.substitute-page .main-block {
  width: 75%;
}

.substitute-page .left-block {
  width: 25%;
}

.substitute-page .left-block {
  .profession {
    margin: 10px;
    top: 24px;
    left: 12px;
    z-index: 2;

    display: flex;
    position: relative;
    align-items: center;
    background-color: #898d92;
    padding: 5px;
    border-radius: 15px;
    height: 17px;
    color: white;
    width: max-content;
    font-size: 16px;

    p {
      margin: 0 10px 0 30px;
      font-size: 14px;
    }

    .profession-icon {
      height: 17px;
      width: 17px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      padding: 5px;
      border-radius: 15px;
      background-color: #6d7278;
    }
  }
}

.substitute-page .administrative-details {
  width: 60%;
  font-size: 18px;
  padding-bottom: 40px;
}

.substitute-page .views {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 70px;
  top: -120px;
  background-color: white;
  z-index: 20;
}

.substitute-page .avatar {
  height: 180px;
  width: 180px;
  border-radius: 90px;
  overflow: hidden;
  margin: 20px 0;
  background-color: $light-grey;
  align-self: center;
}

.substitute-page .avatar img {
  width: 100%;
}

.substitute-page .introduction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.substitute-page .graduation {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 200px;
}

.substitute-page .school {
  display: flex;
  align-items: center;
  img {
    padding-right: 0.2rem;
  }
}

.substitute-page h2 {
  position: relative;
  font-size: 32px;
  margin-bottom: 10px;
}

.substitute-page h2 > .type {
  display: inline-flex;
  align-items: center;

  position: relative;
  top: 2px;

  background-color: $grey4;
  color: $grey;
  padding: 4px 7px;
  border-radius: 8px;
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 13px;
  i {
    font-size: 13px;
    margin-right: 0.4rem;
  }

  &.substitute {
    background-color: $light-green2;
    color: $grey;
  }
}

.substitute-page h2 > img {
  height: 60px;
  margin: -50px 0 -50px 10px;
}

.substitute-page h3 {
  position: relative;
  font-size: 24px;
  margin-bottom: 10px;
}

.substitute-page .administrative-details > div {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  height: 80px;
}

.substitute-page .name {
  margin: -50px 0;
  justify-content: space-between;
  position: relative;
  bottom: 18px;
  h2 {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }
}

.substitute-page .administrative-details > div > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.substitute-page .is-verified {
  display: flex;
  width: 100%;
  justify-content: space-between;

  & i {
    margin-left: 1rem;
    color: $blue;
  }
}

.substitute-page .is-not-verified {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: $grey3;

  & i {
    margin-left: 1rem;
  }
}

.substitute-page .skill {
  border: 1px solid $main-green;
  font-size: 13px;
  padding: 10px 10px;
  min-width: 115px;
  border-radius: 50px;
  margin: 10px 0;
  text-align: center;
}

.substitute-page .skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}

.substitute-page .introduction h2 {
  margin: 20px 0;
}

.substitute-page .description {
  margin-bottom: 50px;
  /* text-align: justify; */
  font-size: 16px;
  line-height: 21px;
  padding-right: 10px;

  .grey {
    color: $grey3;
  }
}

.substitute-page .backward {
  font-size: 20px;
  color: $main-green;
  cursor: pointer;
  margin: 30px 0 30px 30px;
}

.substitute-page .backward i {
  margin-right: 20px;
}

.substitute-page .light-text {
  font-family: $light-font;
  margin: 30px 0;
}

.substitute-page .office-choice {
  display: flex;
  align-items: center;
  font-family: $light-font;
}

.substitute-page .alerts-header {
  padding: 20px 0;
  display: flex;
}

.substitute-page .alerts-header > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    color: $peach;
    margin-top: 15px;
    font-size: 17px;
  }
}

.substitute-page .logo-background {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.substitute-page .logo-background img {
  height: 70px;
}

.substitute-page .alerts-header i {
  font-size: 20px;
  color: white;
}

.substitute-page .views .deadline {
  font-size: 12px;
  font-family: $light-font;
  font-style: italic;
}

.substitute-page .ordinal {
  max-width: 350px;
}

.substitute-page .is-favorite {
  justify-items: flex-end;
  margin-right: 30px;
  color: $peach;
  font-size: 24px;
  height: fit-content;
  cursor: pointer;
}

.substitute-page .activity-details {
  background-color: white;
  border-radius: 15px;
  margin: 10px;
  padding: 20px;
  flex: 1;
  font-size: 14px;

  h2 {
    display: flex;
    align-items: center;
    margin: 30px 0;
  }

  .new-feature {
    line-height: 20px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    border-radius: 5px;
    background-color: $grey3;
    color: $grey;
    margin-left: 10px;
  }

  div {
    font-family: $light-font;
    margin-bottom: 10px;

    span:first-child {
      font-weight: bold;
    }

    span:not(:first-child) {
      margin-left: 5px;
    }
  }
}

.substitute-page .sector {
  position: sticky;
  top: 0;
  background-color: $grey5;
  padding: 15px 30px;
  border-radius: 10px;
  &.in {
    color: $main-green;
  }
  &.out {
    color: $orange;
  }
}

.substitute-page {
  .pagination {
    margin-top: 50px;
  }

  .pagination-alerts {
    li {
      &.previous,
      &.next {
        & a {
          color: $main-green;
        }
      }

      &.active {
        background-color: $main-green;
        border-color: $main-green;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .substitute-page .content {
    padding: 100px 10px 10px 10px;
  }

  .substitute-page .content .container {
    flex-direction: column;
    width: calc(100vw - 20px);
  }

  .substitute-page .left-block {
    width: calc(100vw - 20px);
  }

  .substitute-page .main-block {
    width: calc(100vw - 20px);
  }

  .substitute-page .administrative-details {
    max-width: 420px;
  }

  .substitute-page .views {
    width: 100px;
  }

  .substitute-page .top-block {
    justify-content: space-between;
  }

  .substitute-page .credits-block {
    /* margin-left: 150px; */
    margin-right: 240px;
  }

  .substitute-page .skills {
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  .substitute-page .skill {
    margin-right: 10px;
  }
}
