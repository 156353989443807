.tips {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $main-green;
  height: 100vh;
  padding: 0 30px;
  position: fixed;
  right: 0;
  z-index: 9;
}

.tips .thumbnail {
  width: 385px;
  background-color: white;
  border: 1px solid $peach;
  border-radius: 20px;
  height: 607px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  position: relative;
}


.tips .thumbnail h3 {
  font-size: 36px;
  line-height: 42px;
  padding: 40px 50px 0 50px;
}

.tips .thumbnail .tips-paragraphs {
  margin-top: 10px;
  margin-bottom: auto;
}

.tips .thumbnail .paragraph {
  /* justifying text makes it hard to read */
  padding: 20px 50px;
  font-size: 14px;
  /*14px is better looking*/
  line-height: 21px;
}



.tips .small-text {
  font-size: 12px;
  text-align: center;
  padding-top: 60px;
  border-top: 1px solid $peach;
  margin: 20px 20px 100px 20px;
  // position: absolute;
  // bottom: 0;
  // margin-top: auto;
}

.tips .green-text {
  color: $main-green;
}

.tips .circle-selector {
  display: flex;
  // position: absolute;
  bottom: 175px;
  margin: 0 auto;
  // left: 38%;
  // right: 38%;
}

.tips .grey-circle {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  margin: 0 5px;
  border: none;
  background-color: $light-grey;
  cursor: pointer;
}

.tips .green-circle {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  margin: 0 5px;
  border: none;
  background-color: $main-green;
  cursor: pointer;
}

/* .tips .round-selector:focus {
    background-color: $main-green;
  }
  
  .tips .selectors {
    height: 40px;
    border-bottom: 1px solid $peach;
    margin: 0 20px;
    text-align: center;
  } */
@media only screen and (max-width: 1530px) {
  .tips .thumbnail {
    width: 20vw;
  }
}

@media only screen and (max-width: 1350px) {
  .tips {
    display: none;
  }
}