.top-discussion-thumbnail {
  position: absolute;
  left: 381px;
  top: 30px;
  width: calc(100vw - 349px - 330px);
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 1;

  div {
    padding-left: 50px;
  }

  .firstname {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-top: 20px;
  }

  .stats {
    display: flex;
    align-items: center;
    border-bottom: 1px $grey3 solid;
    padding: 10px 50px;
    font-size: 13px;
    height: 30px;

    > span:not(:first-child) {
      margin-left: 50px;
    }

    > span.to-archive {
      margin-left: auto;

      i {
        margin-left: 10px;
      }

      &:hover {
        color: $blue;
        cursor: pointer;
      }
    }

    > span.archived {
      margin-left: auto;
      font-style: italic;
      color: $grey2;
    }

    > span.loader {
      margin-left: auto;
      color: $blue;
      display: flex;
      align-items: center;
    }

    span.isActive {
      display: inline-block;
      min-width: 13px;
      min-height: 13px;
      border-radius: 100%;
      margin-right: 5px;
      background-color: $main-green;
    }
  }

  .presentation {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    color: $grey;
    font-size: 13px;
  }

  .details {
    text-align: center;
    margin-bottom: 10px;
    color: $grey2;
    font-size: 12px;
  }

  &:hover {
    background-color: $grey5;
  }
}
