.mission-thumbnail {
  position: relative;
  width: 30%;
  min-width: 300px;
  max-width: 400px;
  padding: 30px;
  border: 1px $grey4 solid;
  border-radius: 20px 20px 20px 0;

  .timestamp {
    position: absolute;
    top: -20px;
    right: 20px;
    font-size: 10px;
    color: $grey2;
    text-align: end;
    font-style: italic;
  }

  .title {
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .mission-title {
    color: $grey;
    margin-bottom: 10px;
  }

  .date-title {
    font-size: 12px;
    color: $grey2;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .dates {
    display: flex;
    margin-bottom: 40px;

    .date-thumbnail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: $grey5;
      width: 100px;
      margin-right: 30px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 10px;

      div {
        text-align: center;
      }

      div:first-child {
        font-size: 25px;
        margin-bottom: 30px;
      }
    }
  }

  .message-content {
    color: $grey;
    font-size: 12px;
    line-height: 15px;
    word-wrap: break-word;
  }

  .avatar {
    position: absolute;
    left: -50px;
    bottom: -30px;
    img {
      height: 40px;
      width: 40px;
      border-radius: 100%;
    }
  }
}
