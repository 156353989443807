@import "./theme.scss";
@import "./reset.scss";
@import "./App.scss";
@import "./Components/Forms/Buttons/Button/Button.scss";
@import "./Components/Forms/Buttons/CheckButton/CheckButton.scss";
@import "./Components/Forms/Buttons/SelectButton/SelectButton.scss";
@import "./Components/Forms/Buttons/ValidButton/ValidButton.scss";
@import "./Components/Forms/Inputs/AuthTextInput/AuthTextInput.scss";
@import "./Components/Forms/Inputs/CheckBox/CheckBox.scss";
@import "./Components/Forms/Inputs/Toggle/Toggle.scss";
@import "./Components/Forms/Inputs/CodeInput/CodeInput.scss";
@import "./Components/Forms/Inputs/MultipleLinesInput/MultipleLinesInput.scss";
@import "./Components/Forms/Inputs/SearchBar/SearchBar.scss";
@import "./Components/Forms/Inputs/ListSelect/ListSelect.scss";
@import "./Components/Forms/Inputs/MultipleSelectDropDown/MultipleSelectDropDown.scss";
@import "./Components/Forms/Inputs/ListSelectMultiple/ListSelectMultiple.scss";
@import "./Components/Forms/Inputs/ListSelectMultipleWithInput/ListSelectMultipleWithInput.scss";
@import "./Components/Header/Header.scss";
@import "./Components/Header/UserMenu/UserMenu.scss";
@import "./Components/HighlightedTip/HighlightedTip.scss";
@import "./Components/ProgressBar/ProgressBar.scss";
@import "./Components/ResponsiveError/ResponsiveError.scss";
@import "./Components/Tips/Tips.scss";
@import "./Components/InfoBox/InfoBox.scss";
@import "./Containers/Ads/Ads.scss";
@import "./Containers/Ads/Statistics/AdsStatistics.scss";
@import "./Containers/Ads/Slide1/AdsThumbnail/AdsThumbnail.scss";
@import "./Containers/Ads/Slide1/Missions.scss";
@import "Containers/Ads/Slide1/AdsList/AdsList";
@import "./Containers/Ads/Slide2/Candidate/Candidate.scss";
@import "./Containers/Ads/Slide2/Candidates.scss";
@import "./Containers/MyCandidates/MyCandidates.scss";
@import "./Containers/Authentification/Authentification.scss";
@import "./Containers/ErrorPage/ErrorPage.scss";
@import "./Containers/Home/Home.scss";
@import "./Containers/MyProfile/MyProfile.scss";
@import "./Containers/NewMission/NewMission.scss";
@import "./Containers/NewMission/Slides/Slide1/WhereAndWhat.scss";
@import "./Containers/NewMission/Slides/Slide2/Terms.scss";
@import "./Containers/NewMission/Slides/Slide3/Description.scss";
@import "./Containers/NewMission/Slides/Slide4/Success.scss";
@import "./Containers/OfficePage/OfficePage.scss";
@import "./Containers/ResetPasswordAuth/ResetPasswordAuth.scss";
@import "./Containers/ResetPasswordAuth/Slide1/ResetRequest.scss";
@import "./Containers/ResetPasswordAuth/Slide2/ResetSuccess.scss";
@import "./Containers/Pricing/Pricing.scss";
@import "./Containers/Pricing/Card/Switch/Switch.scss";
@import "./Containers/Pricing/Card/Card.scss";
@import "./Containers/Pricing/Simulator/Simulator.scss";
@import "./Containers/Subscription/Slides/Slide1/EmailSlide.scss";
@import "Containers/Subscription/Slides/Slide2/PersonalDetails";
@import "Containers/Subscription/Slides/Slide3/Role";
@import "Containers/Subscription/Slides/Slide4/MainOffice";
@import "Containers/Subscription/Slides/Slide4/OfficeCard/OfficeCard.scss";
@import "Containers/Subscription/Slides/Slide5/Summary";
@import "Containers/Subscription/Slides/Slide6/Success";
@import "./Containers/Subscription/Subscription.scss";
@import "./Containers/SubstitutesPage/SubstitutePersonalPage/AlertThumbnail/AlertThumbnail.scss";
@import "./Containers/SubstitutesPage/SubstitutePersonalPage/HolderOfficeInfos/HolderOfficeInfos.scss";
@import "./Containers/SubstitutesPage/SubstitutePersonalPage/Experiences/Experiences.scss";
@import "./Containers/SubstitutesPage/SubstitutePersonalPage/Locomotions/Locomotions.scss";
@import "./Containers/SubstitutesPage/SubstitutePersonalPage/Courses/Courses.scss";
@import "./Containers/SubstitutesPage/SubstitutePersonalPage/SendAdsThumbnail/SendAdsThumbnail.scss";
@import "./Containers/SubstitutesPage/SubstitutePersonalPage/SubstitutePersonalPage.scss";
@import "./Containers/SubstitutesPage/SubstitutePersonalPage/SubstitutePersonalPageGhost.scss";
@import "./Containers/SubstitutesPage/SubstitutesList/FiltersBlock/FiltersBlock.scss";
@import "./Containers/SubstitutesPage/SubstitutesList/SubstitutesList.scss";
@import "./Containers/SubstitutesPage/SubstitutesList/SubstituteThumbnail/SubstituteThumbnail.scss";
@import "./Containers/SubstitutesPage/SubstitutesList/SubstituteThumbnail/SubstituteThumbnailGhost.scss";
@import "./Containers/SubstitutesPage/SubstitutesPage.scss";
@import "./Containers/SubstitutesPage/SubstitutePersonalPage/UserHistoric/UserHistoric.scss";
@import "./Containers/UserAlerts/UserAlerts.scss";
@import "./Containers/Discussions/Discussions.scss";
@import "./Containers/Discussions/DiscussionThumbnail/DiscussionThumbnail.scss";
@import "./Containers/Discussions/MissionThumbnail/MissionThumbnail.scss";
@import "./Containers/Discussions/TopDiscussionThumbnail/TopDiscussionThumbnail.scss";
@import "Containers/Discussions/MessageInputForm/MessageInputForm";
@import "./Containers/Discussions/MessageThumbnail/MessageThumbnail.scss";
@import "./Containers/Discussions/CurrentDiscussion/CurrentDiscussion.scss";
@import "./Containers/SubstitutesPage/SubstitutePersonalPage/DiscussionInfos/DiscussionInfos.scss";
@import "./Containers/Discussions/SupportThumbnail/SupportThumbnail.scss";
@import "./Containers/Discussions/SubscriptionPending/SubscriptionPending.scss";
@import "./Containers/Discussions/SubstituteThumbnailLight/SubstituteThumbnailLight.scss";

@font-face {
  font-family: "Arial Rounded MT Bold";
  src: local("Arial Rounded MT Bold"), url("./assets/fonts/arlrdbd.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: $main-font, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $grey;
  /* -webkit-user-select: none; */
  /* -moz-user-select: none; */
  /* -ms-user-select: none; */
  /* user-select: none; */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: $light-font;
  font-size: 14px;
  color: $light-grey;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: $light-font;
  font-size: 14px;
  color: $light-grey;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: $light-font;
  font-size: 14px;
  color: $light-grey;
}

.main-relative {
  position: relative;
}

input,
textarea {
  background-color: #fff !important;
}
