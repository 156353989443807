$main-font: "Arial Rounded MT Bold";
$light-font: "Arial";


// PRIMARY COLOR
$main-green: #25bba5; // rgba(37,187,165,1)
$main-green2: #bee4de;
$main-green3: #ebf5f3;

// SECONDARY COLOR
$light-green: #e1e796;
$light-green2: #e9ecc4;
$light-green3: #f6f7ec;

// MONKEY COLOR #1 (Brown)
$brown: #521515;
$brown2: #D4C5C5;
$brown3: #f4f0f0;

// MONKEY COLOR #2 (Orange)
$orange: #ef5d33;
$orange2: #f1ccc2;
$orange3: #fbf2ef;

// MONKEY COLOR #3 (Yellow)
$yellow: #fcee21;
$yellow2: #fffde3;

// CALL TO ACTION
$blue: #29b6e5;
$blue2: #bfe2ee;
$blue3: #ebf4f7;

// PREMIUM COLOR
$peach: #fbc98f; //rgba(251,201,143,1)
$peach2: #f4e7d9;
$peach3: #fcf9f5;

// TITLES / BACKGROUND
$grey: #525151;
$grey2: #979797;
$grey3: #d8d8d8;
$grey4: #e6e6e6;
$grey5: #f9f9f9;

// OTHERS
$clear-green: #55c7b6;
$light-grey: rgba(100, 100, 100, 0.1);
$grey-shadow: 0px 0px 5px 5px rgba(100, 100, 100, 0.1);
$lightgrey-shadow: 0px 0px 5px 5px rgba(156, 23, 23, 0.05);
$blue-shadow: 0px 0px 5px 5px rgba(41, 182, 229, 0.1);