.mission-to-send {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0;
}

.mission-to-send .validate-white,
  .mission-to-send .validate-blue {
  position: static;
  margin: 0;
  height: 50px;
  font-size: 18px;
}

.mission-to-send .mission-left-block {
  display: flex;
  flex-direction: column;
}

.mission-to-send .mission-dates,
.mission-to-send .mission-type {
  display: flex;
  font-size: 18px;
}

.mission-to-send .mission-dates i,
.mission-to-send .mission-type i {
  margin-right: 10px;
  color: $peach;
}

.mission-to-send .mission-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.mission-to-send .mission-type {
  /* margin-left: 70px; */
  position: absolute;
  left: 450px;
}

.mission-to-send .ads-is-sent {
  width: 220px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.mission-to-send .ads-is-sent .text {
  color: $blue;
  font-size: 18px;
  text-align: center;
}

.mission-to-send .ads-is-sent img {
  position: absolute;
  height: 70px;
  left: -40px;
  top: -5px;
}
