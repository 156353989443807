.message-thumbnail {
  position: relative;
  width: 30%;
  min-width: 300px;
  max-width: 400px;
  padding: 15px 30px;
  border: 1px $grey4 solid;
  border-radius: 20px 20px 20px 0;

  .timestamp {
    position: absolute;
    top: -20px;
    right: 20px;
    font-size: 10px;
    color: $grey2;
    text-align: end;
    font-style: italic;
  }

  .text {
    color: $grey;
    font-size: 12px;
    line-height: 15px;
    word-wrap: break-word;
    min-height: 12px;
  }

  .loader {
    position: absolute;
    right: -50px;
    bottom: -30px;
  }

  .avatar.to-left {
    position: absolute;
    left: -50px;
    bottom: -30px;
  }

  .avatar.to-right {
    position: absolute;
    right: -50px;
    bottom: -30px;
  }

  .avatar img {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    color: red;
  }
}

.message-thumbnail.sending.current-user {
  background-color: $grey5;
  color: $main-green;
  float: right;
  border-radius: 20px 20px 0 20px;
  border: 1px $grey5 solid;
}

.message-thumbnail.current-user {
  &:not(.sending) {
    background-color: $main-green;
    border: 1px $main-green solid;
    border-radius: 20px 20px 0 20px;
    float: right;
    .text {
      color: white;
    }
  }
}
