.ads-page .thumbnail {
  border: 1px solid $light-grey;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  position: relative;
  background-color: white;
  width: 450px;

  .demo-ad {
    position: absolute;
    top: -10px;
    background-color: white;
    padding: 0 8px;
    font-size: 16px;
    font-style: italic;
    color: $grey3;
  }
}

.ads-page .green-filled {
  background-color: $main-green;
  position: absolute;
  right: 28px;
  top: -22px;
  padding: 5px 25px;
  font-size: 12px;
  color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.ads-page .peach-filled {
  background-color: $peach;
  box-shadow: 0 0 5px $peach;
  position: absolute;
  right: 28px;
  top: -22px;
  padding: 5px 25px;
  font-size: 12px;
  color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  i {
    margin-right: 5px;
  }
}

.ads-page .thumbnail .left-block {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .profession {
    &.ghost {
      width: 10rem;
      background-color: #eee;
      &::after {
        box-shadow: none;
      }
    }
    display: flex;
    position: relative;
    align-items: center;
    background-color: #898d92;
    padding: 5px;
    border-radius: 15px;
    height: 17px;
    color: white;
    width: max-content;
    font-size: 16px;
    bottom: 24px;

    p {
      margin: 0 10px 0 30px;
      font-size: 14px;
    }

    .profession-icon {
      height: 17px;
      width: 17px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      padding: 5px;
      border-radius: 15px;
      background-color: #6d7278;
    }
  }
}

.ads-page .thumbnail h4 {
  margin-bottom: 10px;
  margin-right: 45px;
}

.ads-page .bottom-content {
  display: flex;
  align-items: center;
}

.ads-page .bottom-content i {
  color: $peach;
  font-size: 25px;
  margin-right: 10px;
}

.ads-page .dates {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.ads-page .job-type {
  display: flex;
  align-items: center;
}

.ads-page .share {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  border: 1px solid $blue;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: $blue;
  cursor: pointer;
  margin: 0 20px 0 50px;
  position: absolute;
  top: 40px;
  right: 10px;
  transition: background-color 0.1s ease, color 0.1s ease, border 0.1s ease;

  &.forbidden {
    color: $grey2;
    border-color: $grey2;
    &:hover {
      cursor: not-allowed;
      background-color: $grey2;
    }
  }
}

.ads-page .share:hover {
  border-color: white;
  background-color: $blue;
  color: white;
}

.ads-page .share .share-popup {
  position: absolute;
  color: #525151;
  font-family: "Arial Rounded MT Bold";
  font-size: 15px;
  line-height: 17px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
  padding: 24px 48px;
  z-index: 10;
  left: 60px;
  cursor: initial;
}

.ads-page .share .share-popup p {
  max-width: 230px;
  text-align: center;
}

.ads-page .share .share-popup .facebook {
  margin: 14px 0;
  border-radius: 40px;
  background-color: #03b8e2;
  color: white;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 14px 0;
}

.ads-page .share .share-popup .deeplink {
  margin: 14px 0;
  border-radius: 40px;
  border: 1px solid #03b8e2;
  color: $blue;
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 14px;
  user-select: text;
  box-sizing: border-box;
  cursor: pointer;
  /* font-family: $main-font; */
}

.ads-page .share .share-popup .deeplink:hover {
  border: 1px solid #fff;
  background-color: #03b8e2;
  color: #fff;
}

.ads-page .share .share-popup .deeplink span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  padding-right: 10px;
}

.ads-page .share .share-popup .link-copied {
  color: $blue;
  font-weight: 600;
  transition: opacity 1s;
  display: flex;
  text-align: center;
}

.ads-page .share-transparent {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  margin: 0 20px 0 50px;
  position: absolute;
  right: 10px;
}

.ads-page .ads {
  display: flex;
  flex-wrap: wrap;
}

.thumbmail-and-buttons {
  display: flex;
  align-items: center;
}

.ads-page .right-icons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
  height: 130px;

  .forbidden {
    color: $grey2;
    border-color: $grey2;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.ads-page .right-icons > div,
.ads-page .right-icons > a {
  height: 26px;
  width: 26px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $blue;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid $blue;
}

.ads-page .right-icons .grey-pen {
  border: 1px solid $light-grey;
}

.ads-page .right-icons .confirm-delete i {
  font-size: 14px;
  margin-right: 6px;
}

.ads-page .right-icons .confirm-delete {
  height: 26px;
  width: 120px;
  border: 1px solid transparent;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  font-size: 12px;
  background-color: $orange;
}

.ads-page .right-icons .blue-eye {
  div {
    display: flex;
    align-items: center;
  }

  &.forbidden {
    border-color: $grey2;

    i {
      color: $grey2;
    }
  }

  i {
    color: $blue;
  }
}

.ads-page .right-icons .grey-pen {
  height: 26px;
  width: 26px;
  border: 1px solid $light-grey;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light-grey;
  cursor: help;
  font-size: 14px;
  position: relative;
}

.ads-page .mission-is-not-filled {
  padding: 10px 40px;
  border: 1px solid $light-grey;
  border-radius: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 70px;
  font-size: 15px;
  /* z-index: 0; */
  background-color: white;
  align-self: flex-start;
}

.ads-page .mission-is-filled {
  padding: 10px 40px;
  display: flex;
  align-items: center;
  font-size: 15px;
  /* z-index: 1; */
  background-color: white;
  border-radius: 15px;
}

.ads-page .is-filled-block {
  position: absolute;
  left: -1px;
  top: -1px;
  background-color: white;
  width: 410px;
  box-shadow: $grey-shadow;
  border-radius: 15px;
  z-index: 3;
  margin-bottom: 30px;
}

.ads-page .is-filled-block .questions {
  padding: 0 20px 20px 20px;
}

.ads-page .is-filled-block .question {
  margin-bottom: 10px;
}

.ads-page .is-filled-block .info {
  padding: 10px 0;
  font-size: 12px;
  text-align: justify;
}

.ads-page .mission-is-not-filled .is-not-filled {
  font-size: 12px;
  margin-left: 20px;
  padding: 5px 15px 5px 30px;
  background-color: $light-grey;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
}

.ads-page .mission-is-filled .is-filled {
  font-size: 12px;
  margin-left: 20px;
  padding: 5px 30px 5px 15px;
  background-color: $blue;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  color: white;
}

.ads-page .mission-is-not-filled .is-not-filled > div {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  left: 3px;
  bottom: 3px;
}

.ads-page .mission-is-filled .is-filled > div {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  right: 3px;
  bottom: 3px;
}

.ads-page .datas {
  display: flex;
  margin: 15px 0 50px 20px;
  font-size: 14px;
}

.ads-page .datas > div {
  display: flex;
  padding: 5px 15px;
  border: 1px solid $light-grey;
  border-radius: 20px;
  margin-right: 20px;
  align-items: center;

  #notification-badge {
    position: absolute;
    margin: 0 0 20px 140px;
    background: $orange;
    padding: 5px 5px;
    border-radius: 15px;
    color: white;
    font-size: 11px;
    height: 8px;
    min-width: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.ads-page .datas .blue-applications {
  display: flex;
  padding: 5px 15px;
  color: $blue;
  border: 1px solid $blue;
  border-radius: 20px;
  margin-right: 20px;
  cursor: pointer;
  position: relative;

  .notification-new-candidate {
    position: absolute;
    top:-5px;
    background: $orange;
    padding: 5px 5px;
    border-radius: 15px;
    color: white;
    font-size: 11px;
    height: 8px;
    min-width: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.forbidden {
    color: $grey2;
    border: 1px solid $grey2;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.ads-page .datas i {
  margin-right: 5px;
}

.ads-page .all-candidates {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

.ads-page .candidate {
  display: flex;
  width: 115px;
  margin: 10px 0;
  font-size: 12px;
}

.ads-page .candidate > div {
  margin-right: 5px;
}

.ads-page .how-did-you-find {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ads-page .how-did-you-find .role {
  border: 1px solid $peach;
  padding: 5px 5px;
  width: 130px;
  border-radius: 40px;
  margin: 2px 10px 10px 10px;
  font-family: $main-font;
  color: $grey;
  font-size: 12px;
  text-align: center;
}

.ads-page .how-did-you-find .role-blue {
  border: 1px solid $blue;
  padding: 5px 5px;
  width: 130px;
  border-radius: 40px;
  margin: 2px 10px 10px 10px;
  font-family: $main-font;
  color: $grey;
  font-size: 12px;
  text-align: center;
}

.ads-page.how-did-you-find .role:focus {
  border: 3px solid $blue;
  margin: 0 30px 30px 0;
}

.ads-page .button {
  display: flex;
  justify-content: center;
}

.ads-page .validate-blue,
.ads-page .validate-white {
  position: static;
  height: 40px;
  width: 150px;
  font-size: 14px;
  margin: 0;
}

.ads-page .validate-white {
  color: $blue;
}

@media only screen and (max-width: 900px) {
  .ads-page .ads .mission-is-not-filled {
    margin: 0 0 50px 0;
  }
}
