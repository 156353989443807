.substitute-page .locomotions {
  background-color: white;
  border-radius: 15px;
  margin: 10px;
  padding: 20px;
}

.substitute-page .locomotions {
  .locomotions-logos {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(2, 50%);
    grid-template-areas: 
    "byfoot bymotorbike bycar bybus"
    "bybike bytramway bymetro .";


    .locomotion {
      justify-self: center;
      align-self: center;
      padding: 1rem;

      &-active svg {
        fill: $main-green;
      }
    }
  }
}