.pricing-simulator {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  justify-content: center;
  z-index: 22;
  transition: all 0.6s ease;
  /*
     /!\ backdrop-filter not supported by Firefox
   */
  backdrop-filter: blur(15px);
  @include firefox-only {
    margin-top: 120px;
    background-color: $grey5;
    height: calc(100vh - 120px);
  }

  > .content {
    background: linear-gradient(white, $grey4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 900px;
    max-width: 90vw;
    margin: auto;
    padding: 80px 0;
    box-sizing: border-box;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 0 5px grey;

    h1 {
      font-size: 30px;
      border-bottom: 3px solid $grey;
      padding-bottom: 10px;
      text-align: center;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 30px;
    }
  }

  i {
    transition: all 0.6s ease;
    padding: 5px;

    &:hover:before {
      cursor: pointer;
      color: black;
    }
  }

  .selector {
    border-radius: 50px;
    display: inline-block;
    background-color: $grey4;
    box-shadow: 0 0 10px $grey4;
    margin: 30px 0;
    font-size: 13px;

    button {
      border: none;
      padding: 10px 15px;
      background-color: $grey4;
      border-radius: 20px;
      transition: background-color 0.3s ease;

      &:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }

      &:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      &.selected {
        padding: 10px 30px;
        font-weight: bold;
        color: white;
      }

      &.zen {
        &:hover {
          color: white;
          background-color: rgba(0, 0, 0, 0.5);
        }

        &.selected {
          background-color: black;
        }
      }
    }
  }

  input[type="number"] {
    text-align: center;
    border: 0.5px solid $grey;
    width: 80px;
    border-radius: 50px;
    padding: 5px;
    font-weight: bold;
    margin: 30px 10px;
  }

  .range {
    display: flex;
    align-items: center;

    input[type="range"] {
      width: 200px;
    }
  }

  .summary {
    div {
      line-height: 25px;
      padding: 5px;
      text-align: center;
      min-width: 300px;
    }

    .amount {
      margin-top: 30px;
      font-weight: bold;
      border-top: 2px solid black;
      border-bottom: 2px solid black;
    }
  }
}
