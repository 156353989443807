.slide-two {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.slide-two .gender {
  display: flex;
  flex-direction: row;
}

.slide-two .full-phone-number {
  display: flex;
}

.slide-two .country-code {
  width: 120px;
}

.slide-two .country-code input {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 120px;
}

.slide-two .phone-number input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.slide-two .country-selector {
  margin-right: 10px;
}

.slide-two .info {
  max-width: 550px;
  margin-bottom: 50px;
  color: $grey2;
}

.slide-two .firstname-input,
.slide-two .lastname-input,
.slide-two .full-phone-number {
  margin-top: 25px;
}

.slide-two .full-phone-number .phone-number{
  margin-top: 1px;
}

.slide-two .invalid {
  font-size: 12px;
  color: $orange;
  margin-top: 10px;
}
