.switch {
  border-radius: 50px;
  background-color: $grey4;
  box-shadow: 0 0 10px $grey4;
  margin-bottom: 30px;
  font-size: 13px;

  button {
    border: none;
    padding: 10px 15px;
    background-color: $grey4;
    transition: all 0.3s ease;

    &:first-child,
    &:last-child {
      border-radius: 20px;
    }

    &:hover {
      border-radius: 20px;
    }

    &.selected {
      padding: 10px 30px;
      border-radius: 20px;
      font-weight: bold;
    }
  }
}

.smile {
  .switch {
    button {
      &:hover {
        color: black;
        background-color: lightgrey;
      }

      &.selected {
        background-color: $grey2;
        color: white;
      }
    }
  }
}

.happy {
  .switch {
    button {
      &:hover {
        color: $main-green;
        background-color: $main-green3;
      }

      &.selected {
        background-color: $main-green;
        color: white;
      }
    }
  }
}

.zen {
  .switch {
    button {
      &:hover {
        color: white;
        background-color: grey;
      }

      &.selected {
        background-color: black;
        color: white;
      }
    }
  }
}
