.substitute-page {
  &.ghost {    
    &:hover {
      box-shadow: none;
      cursor: default;
    }

    span {
      display: block;
      margin-bottom: 10px;
    }
    
    .content {
      height: calc(100vh - 140px);
      padding: 140px 0 0 0;
      overflow: hidden;
    }

    .introduction {
      height: 550px;
    }

    .administrative-details {
      span {
        margin: 0;
      };
    }
    
    .experiences {
      height: 180px;
    }

    .middle-block {
      height: 220px;
    }

    .bottom-block {
      height: 600px;
    }
  }
}