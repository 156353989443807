.discussions-container {
  display: flex;
  width: 100vw;
  margin-top: 120px;
  position: relative;
  background-color: #eeeeee;

  .collapse-all-discussions {
    width: 350px;
    min-width: 350px;
    height: calc(100vh - 120px);
    overflow: scroll;
    border-right: $grey3 1px solid;
    background-color: white;

    > div {
      &:nth-child(2) {
        margin-top: 120px;
      }
    }

    .archive-selection {
      position: absolute;
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 120px;
      padding: 30px 30px 10px;
      font-size: 19px;
      z-index: 2;
      background-color: white;
      width: 350px;
      box-sizing: border-box;
      border-bottom: 1px solid $grey3;

      .archive-toggle {
        display: flex;
        justify-content: space-evenly;
        font-size: 16px;

        > span {
          padding-bottom: 5px;
          text-align: center;
          position: relative;
          &:hover {
            cursor: pointer;
          }

          &.selected {
            color: $blue;
            border-bottom: 2px solid $blue;
          }

          > .notification-badge {
            position: absolute;
            top: -10px;
            right: -20px;
            background: $orange;
            padding: 5px 5px;
            border-radius: 15px;
            color: white;
            font-size: 11px;
            height: 8px;
            min-width: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .no-discussion {
      flex: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 15px;
      color: $grey2;
      height: calc(100vh - 240px);
    }

    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 240px);
    }

    .old-discussion-loader {
      display: flex;
      font-size: 13px;
      justify-content: center;
      color: $grey2;
      margin: 20px 0;
    }
  }

  .candidate-profile {
    margin-top: 30px;
  }
}
