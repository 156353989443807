.current-discussion {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  max-height: calc(100vh - 250px);
  margin: 30px 30px 0 30px;
  padding: 50px 100px 50px 100px;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: scroll;

  &.isLoading {
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      > span {
        margin-right: 10px;
      }
    }
  }

  .message {
    &:last-child {
      padding-top: 180px;
    }

    &:first-child {
      padding-bottom: 100px;
    }

    &:not(:first-child) {
      padding-bottom: 55px;
    }
  }

  .admin-message {
    color: $grey2;
    font-size: 12px;
    text-align: center;
  }
}
