.candidates-page {
  background-color: $grey5;
  padding: 140px 0;
}

.candidates-page .container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 70px;
  background-color: white;
  border-radius: 20px;
  position: relative;
}

.candidates-page .container .backward {
  position: absolute;
  left: -170px;
  top: 64px;
  font-size: 24px;
  color: $main-green;
  cursor: pointer;
}

.candidates-page .container .backward i {
  margin-right: 20px;
}

.candidates-page .mission-title {
  font-size: 28px;
  padding-bottom: 36px;
}