.holder-office {
  i {
    margin-right: .5rem;
  }

  .header {
    display: flex;
    align-items: center;

    h2 {
      margin: 0;
      margin-right: 1rem;
    }

    .dropdown .dropdown__container span {
      display: inline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 190px;
    }

    .ListSelect {
      margin-bottom: 45px;
    }
  }

  .content {
    background-color: #fff!important;
    padding: 0!important;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .map {
      display: flex;
      margin: 20px;
      width: 460px;
      height: 460px;
      overflow: hidden;
      border-radius: 20px;
    }

    .details {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      min-height: 460px;
      margin: 20px;
      width: 250px;

      &-block {
        h3 {
          font-size: 20px;
          margin-bottom: 20px;
        }
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .type {
        padding: 7px 0;

        span {
          border-radius: 10px;
          background-color: $main-green;
          color: #fff;
          padding: 7px 10px;
        }
      }

      .patient-base {
        .line {
          display: flex;
          padding-left: 20px;
          
          div {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            min-width: 60px;
            max-width: 60px;
            height: 60px;
            padding: 6px;
            border-radius: 12px;
            color: $main-green;
            border: 3px solid $main-green;
            
            span {
              font-size: 26px;
              width: 100%;
              display: flex;
              justify-content: center;
            }
            p {
              font-size: 12px;
            }

            &:nth-child(2) {
              color: $peach;
              border-color: $peach;
            }

            &:nth-child(3) {
              color: $brown;
              border-color: $brown;
            }
          }

          &.disabled {
            div {
              color: $grey3;
              border-color: $grey3;
            }
          }
        }

      }
      
      .opening-hours {
        justify-content: center;
        
        .day {
          display: flex;
          align-items: flex-start;
          margin-bottom: 15px;
          padding-left: 20px;
    
          p {
            margin: 0 15px 0 0;
            width: 100px;
          }
          .times {
            span {
              &.closed {
                color: $grey3;
              }
              display: block;
            }
          }

          &.disabled {
            color: $grey3;
            height: calc(32px * 7);
            p {
              width: 100%;
            }
          }
        }
      }

    }

  }
}