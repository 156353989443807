.user-alert-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    input {
        position: absolute;
        left: -9999px;
    }

    .user-alert-infos {
        display: flex;
        align-items: center;
        border: 1px solid $grey4;
        border-radius: 20px;
        padding: 20px;
        width: 100%;
        justify-content: space-between;
    }
}

.alert-left-container {
    display: flex;
    flex: 0.9;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
    .top-left-infos {
        display: flex;
        align-items: center;
        .job-name {
            background-color: rgba(109, 114, 120, 0.8);
            color: white;
            display: flex;
            align-items: center;
            border-radius: 20px;
            padding-right: 10px;
            width: fit-content;
            i {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 7px;
                border-radius: 20px;
                padding: 7px;
                background-color:  #6D7278;
            }
        }

        .searching-job {
            display: flex;
            align-items: center;
            color: $blue;
            margin-left: 10px;

            .blue-circle {
                height: 10px;
                width: 10px;
                border-radius: 5px;
                background-color: $blue;
                margin-right: 7px;
            }
        }
    }

    .middle-left-infos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .middle-info {
            display: flex;
            align-items: center;
        }
        i {
            font-size: 25px;
            margin-right: 7px;
            color: $peach;
        }
    }

    .bottom-left-infos {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        
        p {
            padding: 7px 15px;
            border: 1px solid $blue;
            border-radius: 20px;
            text-align: center;
            font-size: 12px;
            min-width: 100px;
        }
    }
}

.user-alert-search {
    border: 2px solid $blue;
    padding: 10px;
    border-radius: 35px;
    font-size: 30px;
    color: $blue;
    cursor: pointer;

    &:hover {
        background-color: $blue;
        color: white;
    }
}

.delete-button {
    margin-right: 20px;
    .toggle-delete {
        cursor: pointer;
        background-color: white;
        border: 2px solid $blue;
        border-radius: 20px;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          color: $blue;
        }
    }

    .confirm-delete {
        background-color: $orange;
        color: white;
        display: flex;
        cursor: pointer;
        padding: 7px 12px;
        border-radius: 20px;
        width: max-content;
        i {
            padding: 0 5px;
        }
    }
}

// ********** Background:
.slider-v2 {
    position: relative; display: block;
    width: 100px; height: 3em;
    cursor: pointer;
    transition: 350ms;
    p {
        position: absolute;
        top: 27px;
        left: 54px;
        font-size: 12px;
        color: $blue;
    }
  }
  
  // ********** Switch:
  .slider-v2::after {
    position: absolute; content:'';
    width: 28px; height: 28px;
    top: 19px; left: 12px;
    border-radius: 50%;
    transition: 250ms ease-in-out;
    background: $blue;
  }
  
  // ********** Channel:
  .slider-v2::before {
    position: absolute; content:'';
    width: 140px; height: 30px;
    top: 1em; left: 0.5em;
    border-radius: 20px;
    transition: 250ms ease-in-out;
    background: white;
    border: 2px solid $blue;
  }
  
  input:checked + .slider-v2::before {
      background-color: $blue;

  }

  input:checked + .slider-v2 p {
      color: white;
  }
  
  input:checked + .slider-v2::after {
      left: 120px;
      background: white;
  }
  