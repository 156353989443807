.ads-page {
  background-color: $grey5;
  padding: 140px 0 200px 0;
}

.ads-page .container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 70px;
  min-height: 650px;
  background-color: white;
  border-radius: 20px;
  position: relative;
}

.ads-page h2 {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 17px;
}

.ads-page h3 {
  font-size: 28px;
  line-height: 32px;
  margin: 60px 0 10px 0;
}

.ads-page h4 {
  font-size: 21px;
  line-height: 24px;
  margin-right: 20px;
  margin-bottom: 30px;
}

.ads-page .establishment-name {
  font-size: 16px;
  border: 1px solid $light-grey;
  padding: 10px 30px;
  border-radius: 25px;
  margin-bottom: 30px;
  max-width: 200px;

  // ellipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ads-page .status {
  display: flex;
  cursor: pointer;
  margin-bottom: 40px;
}

.ads-page .status li {
  margin-right: 30px;
  font-size: 14px;
}

.ads-page .blue-link {
  color: $blue;
}

.ads-page .office-name {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .ads-page {
    padding: 0;
  }

  .ads-page .container {
    width: calc(100% - 140px);
    border-radius: 0;
    padding-top: 60px;
  }

  /* .missions-ads .content {
    padding: 0;
  } */
}
