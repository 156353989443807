.reset-password .slide-one .invalid {
  font-size: 12px;
  color: $orange;
  height: 14px;
}

.reset-password .slide-one {
  margin: 200px auto 0 auto;
}

.reset-password .slide-one .valid {
  font-size: 12px;
  color: $main-green;
  height: 14px;
}

.reset-password .slide-one .backward {
  position: absolute;
  top: 200px;
  left: 70px;
  font-size: 24px;
  cursor: pointer;
}

.reset-password i .slide-one {
  margin-right: 20px;
}

.reset-password .slide-one .text {
  margin-bottom: 100px;
  max-width: 800px;
  text-align: center;
  font-size: 18px;
}

.reset-password .slide-one .reset-title {
  font-size: 40px;
  margin-bottom: 30px;
  color: $main-green;
}

.reset-password i {
  margin-right: 20px;
}

.reset-password .center {
  text-align: center;
}
