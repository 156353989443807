.slide-one {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .validation-email {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .validate-blue {
      margin-top: 0;
      margin-right: 20px;
    }

    .validate-white {
      margin-top: 0;
      margin-right: 20px;
    }
  }
}

.slide-one .content .minimum-eight {
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 12px;
  padding-left: 20px;
  color: $grey2;
}

.slide-one .content .invalid {
  font-size: 12px;
  padding-left: 20px;
  color: $orange;
  cursor: pointer;
  margin-top: 10px;
}

.slide-one .info {
  max-width: 550px;
  margin-bottom: 50px;
  color: $grey2;
}

.slide-one .click-here {
  cursor: pointer;
  color: $main-green;
  padding: 10px 0;
}

.slide-one .password-input {
  margin-top: 25px;
}

.slide-one .forget-password {
  margin-top:3px;
  font-size: 10px;

  &:hover {
    text-decoration: underline;
  }
}
