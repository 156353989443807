.responsive-error {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.responsive-error .computer {
  width: 50%;
}

.responsive-error .logo {
  width: 30%;
  margin-bottom: 50px;
}

.responsive-error > div {
  margin-top: 50px;
  max-width: 270px;
  font-size: 12px;
  text-align: justify;
}
