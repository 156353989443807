$printed: #40B6A1;
$searched: #FBC98F;
$seen: #03B8E2;

$width-button-calendar: 250px;

.mission-stats {
  background-color: $grey5;
  padding: 170px 0 200px 0;

  .block {
    max-width: 900px;
    margin: 20px auto;
    padding: 40px 70px;
    background-color: white;
    border-radius: 20px;
    position: relative;

    &-first {
      .candidates-journey {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        
        .journey-point {
          .average {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            font-size: 14px;
            margin-bottom: 14px;
            line-height: 24px;

            p {
              width: 100%;
              height: auto;
              text-align: center;
            }
            
            span {
              display: block;
              font-size: 36px;
              margin-top: 7px;
            }
            
            &.global {
              font-size: 11px;
              color: $grey3;
              margin-bottom: 0px;
              line-height: 18px;
              
              span {
                margin-left: 5px;
                font-size: 20px;
                margin-top: 0px;
              }
            }
          }

          .button-edit-mission {
            width: 180px;
            height: auto;
            font-size: 14px;
            line-height: 14px;
            padding: 7px;
            border: 2px solid $blue;
            i {
              font-size: 13px;
              color: $blue;
              margin-right: .3rem;
            }

            &:hover {
              i {
                color: white;
              }
            }
          }

          // PREVIEW CANDIDATES
          .preview-candidates {
            &:hover {
              cursor: pointer;
            }
            height: 36px;
            width:100%;            
            display: flex;
            justify-content: center;
            align-items: center;

            .candidate {
              position: relative;
              // background-color: $grey5;
              height:27px;
              width: 27px;
              border-radius: 100%;
              border: 2px solid white;
              z-index: 1;

              img {
                // position: relative;
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 100%;
                height:27px;
                width: 27px;
                z-index: -1;
              }


              &:nth-child(4) {
                left: -78px;
                z-index: 1;
              }
              &:nth-child(3) {
                left: -26px;
                z-index: 2;
              }
              &:nth-child(2) {
                left: 26px;
                z-index: 3;
              }
              &:nth-child(1) {
                left: 78px;
                z-index: 4;
              }

              &.empty {
                position: relative;
                background-color: $grey5;
                height:25px;
                width: 25px;
                border-radius: 100%;
                border: 2px dotted $grey4;
              }

              &-more {
                background-color: $grey4;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                span {
                  letter-spacing: -0.05em;
                  padding-right: 0.2em;
                }
              }
            }
          }
        }
  
        span i {
          color: $main-green;
          font-size: 24px;
        }

        &.skeleton {  
          .journey-point {
            span {
              display: block;
              &.react-loading-skeleton {
                margin: 2px;
              }
            }  
          }
          span i {
            color: $grey4;
            font-size: 24px;
          }
        }
      }

      .demo-label {
        position: absolute;
        top: 40px;
        right: 60px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;

        i {
          margin-right: 5px;
        }

        span {
          background-color: $searched;
          color: black;
          margin-bottom: 20px;
          justify-content: flex-end;
          padding: 10px;
          border-radius: 5px;
          border: solid 1px $grey2;
        }
      }
    }

    &-second {
      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;

        h3 {
          display: flex;
        }

        .toggle-radio {
          input[type="radio"] {
            opacity:0;
            width: 100%;
            position:relative;
            z-index: 1;
            top: 20px;
            &:not(:checked) {
              &:hover {
                cursor: pointer;
              }
            }
          }
  
          display: flex;
          justify-content: center;
          align-items: center;
            
          div {
            position: relative;
            height: 42px;
            margin: 0 auto;
            &:first-child {
              label{
                border-radius: 7px 0 0 7px 
              }
            }
            &:last-child {
              label {
                border-radius: 0 7px 7px 0;
                border-right: 1px solid $grey2;
              }
            }
          }
  
          label {
            width:100%;
            height:100%;
            border: 1px solid $grey2;
            border-right:inherit;
            color: $grey2;
            padding: 8px 10px;
            font-size: 14px;
          }
  
          input {
            &:checked + label {
              background-color:$main-green;  
              top:0;
              left:0;
              border: 1px solid $main-green !important;
              z-index:2;
              color: white
            }
            &:not(:checked) + label {
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      .period {
        p {
          margin-right: 10px;
        }        
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .calendar-toggle {
          width: $width-button-calendar;
          i {
            color: $grey2;
            font-size: 24px;
            margin-top: 2px;
          }
          .blue-input {
            height: 90%;
            padding: 0 17px;
            border: 1px solid $grey2;
            color: #828282;
          }
        }

        .caption {
          margin-left: 1rem;
          color: $grey2;
        }
      }

      ul {
        display: flex;
        justify-content: space-evenly;
        margin: 36px 0;

        li {
          display: inline;
          padding-bottom: 5px;
          cursor: pointer;
          padding: 0 15px 8px 15px;
          color: $grey3;
          &:hover {
            color: $grey2;
          }
          &.selected {
            cursor: default;
            &.printed {
              color: $printed;
              border-bottom: 2px solid $printed;
            }
            &.searched {
              color: $searched;
              border-bottom: 2px solid $searched;
            }
            &.seen {
              color: $seen;
              border-bottom: 2px solid $seen;
            }
          }
          i {
            margin-right: 0.5rem;
          }
        }
      }

      h3 {
        display: inline;
        margin:0;
      }

      input {
        margin: 0;
      }
    }
  }

  h2 {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 17px;

    &.title {
      max-width: calc(900px + (70px * 2));
      margin: 0 auto;  
    }
  }

  h3 {
    font-size: 24px;
    margin-bottom: 17px;
    display: inline-flex;
    align-items: center;
  }

  .info {
    margin:0 0.5rem;
    padding-bottom: 2px;
    i {
      color: $grey3;
      font-size: 16px;
    }
  }

  .post-scriptum {
    margin-top: 40px;
    text-align: end;
    font-size: 12px;
    color: $grey2;
  }

  // CALENDAR
  .calendar {  
    &-toggle {
      margin:0;
      width: $width-button-calendar;
      z-index: 2;
      i.blue-selector {
        top: 5px;
      }
    }

    color: #fbc98f;
    border: 1px solid $grey2;
    position: absolute;
    overflow: hidden;
    width: $width-button-calendar;
    border-top: none;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 1;
    left: 156px;
    top: 115px;
    padding-top: 30px;

    .react-calendar__month-view__days__day {
      &:hover {
        color: white;  
      }

      &--weekend {
        color: $grey2;
        font-family: $light-font;
        font-size: 12px;
      }
    }
    
    .react-calendar__tile {
      &:hover, &:focus {
        background-color: $peach;
      }

      &--active {
        background-color: $peach;
        color: white;  
        border-radius: 0px;
      }

      &--range {
        background-color: $peach;
        color: white;  
        border-radius: 0px; 
        &Start {
          border-radius: 5px 0 0 5px;
        } 
        &End {
          border-radius: 0 5px 5px 0;
        } 
      }

      &--rangeBothEnds {
        background-color: $peach;
        color: white;  
        border-radius: 0px; 
      }

      &--hasActive {
        background-color: #ffba6b;
        color: white;
      }
    }

    button {
      color: $grey;
      font-family: $light-font;
      font-size: 12px;
    }
  }

  // BACKWARD
  .backward {
    position: relative;
    right: 262px;
    font-size: 24px;
    color: $main-green;
    cursor: pointer;
    i {
      margin-right: 20px;
    }
  }
}