.all-substitutes .content {
  background-color: $grey5;
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 120px);
  padding-top: 120px;
}

.all-substitutes .substitutes-result {
  padding: 40px;
  margin-left: 305px;
  position: relative;
}

.all-substitutes .total-substitutes {
  font-size: 32px;
}

.all-substitutes .all-thumbnails {
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.all-substitutes {
  position: relative;
}

.all-substitutes .surprised-monkey {
  width: 40%;
}

.all-substitutes .no-result p {
  max-width: 400px;
  text-align: center;
  margin-top: -100px;
}

.all-substitutes .no-result {
  width: 100%;
  margin-left: 305px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.substitutes-result .up-arrow {
  color: white;
  font-size: 40px;
  padding: 15px;
  border-radius: 50%;
  background-color: $blue;
  opacity: 30%;
  position: fixed;
  bottom: -70px;
  right: 50px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  &:hover {
    opacity: 100%;
    box-shadow: $blue-shadow;
  }
}

@media only screen and (max-width: 1200px) {
  .all-substitutes .no-result p {
    margin-top: -50px;
    font-size: 14px;
    max-width: 300px;
  }
}
