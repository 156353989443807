.reset-password {
  height: 0vh;
}

.reset-password .content {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reset-password .top-logo {
  position: absolute;
  width: 150px;
  top: 50px;
  left: 100px;
}

.reset-password button {
  margin-top: 100px;
}
