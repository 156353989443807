.office-page {
  background-color: $grey5;
  padding: 140px 0 100px 0;
}

.public-office-button {
  position: fixed;
  top: 185px;
  z-index: 10;
  right: 0px;
  height: 120px;
  width: 180px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  color: white;
  background-color: $main-green;
  box-shadow: 10px 10px 5px -6px rgba(0, 0, 0, 0.42);

  &:visited {
    color: white;
  }

  &:hover {
    color: white;
    opacity: 0.8;
  }
}

.office-page .container {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 70px;
  min-height: 500px;
  background-color: white;
  border-radius: 20px;
}

.office-page h2 {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 17px;
}

.office-page h3 {
  font-size: 28px;
  line-height: 32px;
  margin: 60px 0 10px 0;
}

.office-page h4 {
  font-size: 21px;
  line-height: 24px;
  margin-bottom: 30px;
}

.office-page .office-completion {
  display: flex;
  align-items: center;
  position: relative;
}

.office-page .office-completion .dash-line {
  position: absolute;
  width: 80%;
  left: 5%;
  top: 40px;
  z-index: 0;
}

.office-page .office-completion img:nth-child(1) {
  width: 13%;
  height: 100%;
  margin-top: -30px;
  z-index: 1;
}

.office-page .office-completion img:nth-child(2) {
  width: 15%;
  height: 100%;
  margin-top: -30px;
  z-index: 1;
}

.office-page .office-completion img:nth-child(3) {
  width: 20%;
  height: 100%;
  margin-top: -30px;
  z-index: 1;
}

.office-page .office-completion img:nth-child(4) {
  width: 23%;
  height: 100%;
  margin-top: -30px;
  z-index: 1;
}

.office-page .office-completion img:nth-child(5) {
  width: 28%;
  height: 100%;
  margin-top: -30px;
  z-index: 1;
}

.office-page .green-circle {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  position: absolute;
  background-color: $main-green;
  left: -45px;
}

.office-page .step {
  position: relative;
}

.office-page .green-bar {
  position: absolute;
  width: 5px;
  height: 700px;
  left: -32.5px;
  background-color: $main-green;
}

.office-page .green-bar-loading {
  position: absolute;
  width: 5px;
  height: 740px;
  left: -32.5px;
  background-color: $main-green;
}

.office-page .subtitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.office-page .subtitle h3 {
  margin: 20px 0 10px 0;
}

.office-page .subtitle .fa-chevron-circle-down {
  margin: 20px 0 10px 0;
  font-size: 40px;
  color: $blue;
  cursor: pointer;
}

.office-page .subtitle .fa-chevron-circle-right {
  margin: 20px 0 10px 0;
  font-size: 40px;
  color: white;
  cursor: pointer;
  background-color: $blue;
  border-radius: 20px;
}

.office-page .profession-select {
  border: 2px solid $blue;
  min-width: 200px;
  padding: 15px;
  border-radius: 40px;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  content: none !important;
}

.office-page input,
.office-page select {
  font-family: $main-font;
}

.office-page .address {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.office-page .address .input-block {
  width: 350px;
  margin: 0 40px 40px 0;
}

.office-page .address ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: $main-font;
  font-size: 20px;
  color: $light-grey;
}

.office-page .address :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: $main-font;
  font-size: 20px;
  color: $light-grey;
}

.office-page .address ::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: $main-font;
  font-size: 20px;
  color: $light-grey;
}

.office-page .general-area-infos {
  display: flex;
  margin: 40px 0;
}

.office-page .social-links {
  display: flex;
  flex-wrap: wrap;

  .social-link {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 20px;

    .social-logo {
      min-width: 37px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid;
      margin-right: 15px;
      border-radius: 50%;
      color: $peach;
      border-color: $peach;
    }

    .social-logo-blue {
      color: $blue;
      border-color: $blue;
    }

    .input-block {
      width: 300px;
      margin: unset;

      input {
        font-size: 16px;
        margin: unset;
      }

      ::placeholder {
        font-size: 16px;
        color: #ccc;
      }
    }

    .blue-input {
      margin: unset;
    }
  }
}

.office-page .wrapper {
  border: 1px solid $peach;
  border-radius: 10px;
  overflow: hidden;
  width: 740px;
}

.office-page .toolbar {
  border-bottom: 1px solid $peach;
  width: 740px;
}

.office-page .editor {
  height: 300px;
  width: 740px;
  padding: 0px 30px;

  &::-webkit-scrollbar {
    width: 0.8em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $peach;
  }

  .DraftEditor-root {
    height: 50%;
  }
}

.office-page .full-phone-number {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: -10px 0 40px 0;
}

.office-page .add-practitionner {
  display: flex;
  flex-direction: column;
  margin: 0px 0;
}

.office-page {
  .beds-and-places {
    display: flex;
    margin-top: 40px;

    .input-block {
      margin-bottom: 0;
    }

    .beds {
      width: 280px;
      height: auto;
    }

    input {
      width: 60px;
    }
  }
}

.office-page .country-code .input-block {
  width: 120px;
  /* margin: 0 10px 40px 0; */
}

.office-page .country-code input {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.office-page .phone-number .input-block,
.office-page .profession .input-block {
  width: 300px;
  margin: 0 0 0 10px;
}

.office-page .profession:first-of-type {
  margin-bottom: 60px;
  position: relative;
}

.office-page .profession:first-of-type::after {
  content: "";
  height: 1px;
  display: block;
  position: absolute;
  width: 90%;
  background-color: lightgrey;
  left: 2%;
  bottom: -45px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.office-page .phone-number input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.phone-number input::-webkit-outer-spin-button,
.phone-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.phone-number input[type="number"] {
  -moz-appearance: textfield;
}

.profession-select-wrapper {
  display: flex;
  align-items: center;
}

.profession-row-wrapper {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.step-block .MuiInputBase-root,
.MuiPaper-root .MuiListItem-button {
  font-family: $main-font;
}

.profession-select-wrapper .profession-select-qty .MuiInputBase-input,
.profession-row-wrapper .profession-select-qty .MuiInputBase-input {
  border: 1px solid $orange;
  border-radius: 40px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 60px;
  padding: 10px;
  margin-right: 10px;
  font-size: 20px;
  text-align: end;
}

.profession-select-wrapper .MuiSelect-icon {
  top: 0px;
  right: 15px;
}

.step-block .MuiDivider-root {
  margin-top: 30px;
  margin-bottom: 30px;
}

.profession-select-wrapper .MuiSvgIcon-root {
  width: 2em;
  height: 2em;
  fill: $orange;
}

.MuiFormControl-root:focus-within .MuiSelect-icon {
  fill: $blue;
}

.profession-select-wrapper .MuiSelect-select.MuiSelect-select {
  padding-right: 44px;
}

.profession-row-input .MuiInputBase-input {
  border: 1px solid $orange;
  border-radius: 40px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 320px;
  padding: 10px;
  margin-right: 10px;
  font-size: 20px;
  /* font-weight: 600; */
}

.select-placeholder {
  color: $grey4;
}

.profession-select-wrapper .profession-select-qty .MuiInputBase-input:focus {
  border-color: $blue;
}

.spinner-page {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 140px; */
}

/* .spinner-page > div {
  position: absolute;
  left: 47%;
  top: 45%;
} */

.office-page .admins,
.office-page .members {
  margin-bottom: 20px;
}

.office-page .admin,
.office-page .member {
  padding: 20px 0;
  border-bottom: 1px dashed $peach;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.office-page .name-and-role {
  display: flex;
  flex-direction: column;
}

.office-page .name {
  font-size: 18px;
  padding-bottom: 10px;
}

.office-page .email {
  font-size: 15px;
}

.office-page .role {
  padding-top: 10px;
  font-size: 15px;
}

.office-page .admin .grade {
  font-size: 15px;
  padding: 15px 0;
  border: 1px solid $light-grey;
  width: 300px;
  text-align: center;
  border-radius: 30px;
}

.office-page .member .grade {
  font-size: 15px;
  padding: 15px 0;
  border: 1px solid $peach;
  width: 300px;
  text-align: center;
  border-radius: 30px;
}

.office-page .total-beds {
  display: flex;
  flex-direction: row;
}

.office-page .total-beds > div {
  margin-right: 150px;
}

.office-page .total-beds .input-block {
  width: 200px;
}

.office-page .validate-white,
.office-page .validate-orange {
  position: static;
  margin: 25px 0;
}

.office-page .validate-white {
  color: $blue;
}

.office-page .validate-green {
  color: white;
  background-color: $main-green;
}

.office-page .logo-and-pictures {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.office-page .add-logo {
  width: 200px;
  height: 200px;
  border: 1px solid $light-grey;
  color: $blue;
  margin: 25px 10px 25px 50px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.office-page .add-logo img {
  width: 100%;
}

.office-page .add-picture {
  width: 400px;
  height: 200px;
  border: 1px solid $light-grey;
  color: $blue;
  margin: 25px 10px 25px 10px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.office-page .no-more-picture {
  width: 400px;
  height: 200px;
  border: 1px solid $light-grey;
  color: $orange;
  margin: 25px 10px 25px 10px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.office-page .add-picture:hover,
.office-page .add-logo:hover {
  box-shadow: $grey-shadow;
  border: 1px solid transparent;
}

.office-page .total-pictures {
  width: 100px;
  height: 200px;
  border: 1px solid $light-grey;
  margin: 25px 50px 25px 10px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.office-page .pictures {
  display: flex;
  flex-direction: column;
}

.office-page .add-picture i,
.office-page .add-logo i,
.office-page .total-number {
  font-size: 50px;
  margin-bottom: 10px;
}

.office-page .no-more-picture i {
  font-size: 50px;
  margin-bottom: 10px;
}

.office-page .previews {
  width: 400px;
  margin-left: 300px;
}

.office-page .previews > div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
}

.office-page .preview {
  width: 80px;
  height: 50px;
  margin: 0 5px 20px 5px;
  border: 1px dashed $light-grey;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $light-grey;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  object-fit: cover;
  /* overflow: hidden; */
}

.office-page img {
  width: 100%;
}

.office-page .preview .trash {
  background-color: #ef5d33;
  position: absolute;
  height: 25px;
  width: 25px;
  top: -6px;
  right: -6px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  cursor: pointer;
}

.office-page .softwares-relative {
  position: relative;
}

.office-page .software-list {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 433px;
  justify-content: space-around;
  z-index: 1;
  background-color: white;
  border-left: 1px solid $peach;
  border-right: 1px solid $peach;
  border-bottom: 1px solid $peach;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 10px;
  top: 10px;
}

.office-page .blue-software-list {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 433px;
  justify-content: space-around;
  z-index: 10;
  background-color: white;
  border-left: 1px solid $blue;
  border-right: 1px solid $blue;
  border-bottom: 1px solid $blue;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 10px;
  top: 10px;
}

.office-page .software {
  font-size: 12px;
  padding: 10px 0 10px 40px;
  width: 60px;
  margin: 10px 10px;
  position: relative;
  cursor: pointer;
}

.office-page .software .check-box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $light-grey;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  top: 6px;
  left: 0px;
}

.office-page .software .check-box-blue {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $blue;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  top: 6px;
  left: 0px;
}

.office-page .software .inner-check-box-blue {
  background-color: $blue;
  border: 1px solid $blue;
  height: 12px;
  width: 12px;
  border-radius: 6px;
}

.office-page .software .inner-check-box {
  background-color: transparent;
  border: 1px solid $light-grey;
  height: 12px;
  width: 12px;
  border-radius: 6px;
}

.office-page .softwares-relative .multiple-input-block {
  height: 100px;
  margin: 50px 0;
}

.office-page .softwares-relative .input-block {
  margin-top: 20px;
}

.office-page .softwares-relative .selected-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 750px;
  margin-bottom: 30px;

  .selected-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90px;
    font-size: 12px;
    margin: 0 10px 10px 0;
    padding: 10px 20px;
    border: 1px solid $blue;
    border-radius: 20px;
    cursor: pointer;

    i {
      color: $orange2;
      font-size: 15px;

      &:hover {
        color: $orange;
      }
    }
  }
}

.office-page .establishment-name {
  margin: 60px 0 10px 30px;
  font-size: 16px;
  border: 1px solid $light-grey;
  padding: 10px 50px;
  border-radius: 25px;
}

.office-page .button-loader {
  display: flex;
  align-items: center;
  position: relative;
  margin: 30px 0;
}

.office-page .loader {
  position: absolute;
  left: 180px;
  top: 40px;
}

.office-page .picturesLoading {
  display: flex;
  flex-wrap: wrap;
  margin-left: 340px;
  p {
    margin: -2px 0 0 24px;
    font-size: 16px;
    color: #25bba5;
    .loading-msg {
      font-size: 14px;
      margin-left: 60px;
    }
  }
}

.office-page .add-logo {
  overflow: hidden;
}

.office-page .add-logo img {
  width: 100%;
}

.softwares-relative .rte-hidden {
  display: none !important;
}

.country-selector {
  border: 1px solid $blue;
  background-color: #fff;
  height: 40px;
  padding: 0 10px;
  border-radius: 20px 12px 12px 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.country-selector select {
  border: none;
  background-color: #fff;
}

.full-phone-number .blue-input,
.full-phone-number .orange-input {
  margin: 0;
}

.add-staff,
.remove-staff {
  /* display: inline; */
  margin-left: 10px;
  position: relative;
  /* bottom: 5px; */
  border: none;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 1.4rem;
  transition: box-shadow 0.2s ease;
  box-shadow: none;
}

.add-staff:hover,
.remove-staff:hover {
  box-shadow: 0 0 10px 2px #00000033;
}

.add-staff {
  background-color: $blue;
}

.remove-staff {
  background-color: #ef5d33;
}

.office-page .leaves-avantages {
  display: flex;
  // align-items: center;
}

.office-page .collective-bargaining-agreement {
  z-index: 12;
}

.office-page {
  .list-select {
    z-index: 11;
  }
}

.office-page .RTT {
  width: 180px;
  margin: 0 40px;

  input {
    margin: unset;
  }
}

.avantages-checkboxes {
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  width: 80%;
  justify-content: center;
}

.MuiSlider-vertical .MuiSlider-thumb.Mui-disabled {
  display: none;
}

.office-page .add-patientbase {
  color: $grey2;
  line-height: 20px;
  span {
    color: $blue;
    cursor: pointer;
  }
}

.office-page .opening-hours-block {
  margin-top: 40px;

  .add-openinghours {
    color: $grey2;
    line-height: 20px;
    span {
      color: $blue;
      cursor: pointer;
    }
  }

  .remove-openinghours {
    color: $grey2;
    line-height: 20px;
    margin-bottom: 20px;
    span {
      color: $orange;
      cursor: pointer;
    }
  }

  .week {
    display: block;
  }

  .day {
    width: 100%;
    margin: 10px 0 10px 0;
    display: inline-flex;
    span,
    .toggle {
      margin-right: 1rem;
    }

    span {
      &.day {
        width: 80px;
      }
    }

    .state {
      color: $main-green;
      &.closed {
        color: $grey3;
      }
    }

    .main-line {
      display: inline-flex;
      align-items: center;
      flex-flow: row wrap;
      height: 30px;
    }

    .times-line {
      .MuiTextField-root {
        border-bottom: 1px $grey3 solid;
      }

      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      .time-line {
        height: 30px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        width: 100%;
        span {
          width: 0;
          margin: 0 15px 0 15px;
          display: flex;
          justify-content: center;
          font-size: 22px;
        }

        .remove-time-line {
          margin-left: 1rem;
          padding-top: 2px;
          &:hover {
            cursor: pointer;
          }
          i {
            font-size: 20px;
            color: $grey3;
          }
        }
      }

      .add-time {
        cursor: pointer;
        color: $blue;
        justify-content: flex-start;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .office-page {
    width: 100%;
    padding: 0;
  }

  .office-page .total-pictures {
    display: none;
  }

  .office-page .previews {
    margin: auto;
  }

  .office-page .wrapper {
    width: 100%;
  }
}
