.profile-page {
  background-color: $grey5;
  padding: 160px 0 100px 0;
  position: relative;
  width: 100vw;
  height: calc(100vh - 260px);
}

.profile-page .content {
  width: 900px;
  margin: 0 auto;
  padding: 40px 70px;
  min-height: 500px;
  background-color: white;
  border-radius: 20px;
}

.profile-page .loading-block {
  width: 900px;
  margin: 0 auto;
  padding: 40px 70px;
  min-height: 500px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-page .two-blocks {
  display: flex;
}

.profile-page .two-blocks > div {
  flex: 1;
}

.profile-page .content .gender {
  margin-top: 20px;
  display: flex;
}

.profile-page .validate-blue {
  position: static;
}

.profile-page .validate-white {
  position: static;
}

.profile-page .inputs {
  margin-top: 25px;
}

.profile-page .input-block {
  width: 350px;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.profile-page h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.profile-page h2 {
  font-size: 32px;
  margin-bottom: 30px;
}

.submit-changes {
  margin-top: 35px;
  display: flex;
  align-items: center;
}

.submit-changes .success-message {
  color: $main-green;
  margin-bottom: 50px;
  margin-left: 20px;
  font-size: 12px;
}

.submit-changes .error-message {
  color: $orange;
  margin-bottom: 50px;
  margin-left: 20px;
  font-size: 12px;
}

.left-block, .right-block {
  margin-top: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.left-block {
  .gender-title {
    min-height: 40px;
    display: flex;
    align-items: center;
  }
}

.right-block {
  margin-left: 20px;

  &.border {
    border: $orange2 0.5px solid;
    border-radius: 10px;
    background: rgba(251,242,239,0.25)
  }

  .title {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 58px;
    color: $orange;

    .display-password {
      margin-left: 10px;
    }
  };
}

@media only screen and (max-width: 900px) {
  .profile-page {
    padding: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile-page .content {
    width: 100%;
    height: 100vh;
    border-radius: 0;
    padding: 30px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile-page .two-blocks {
    flex-direction: column;
    align-items: center;
  }
}
