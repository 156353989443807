header {
  border: 0.05px solid lightgrey;
  position: fixed;
  width: 100%;
  z-index: 21;
  background-color: white;
  -webkit-transition: top 1s ease;
  -moz-transition: top 1s ease;
  -o-transition: top 1s ease;
  transition: top 1s ease;
  top: 0;
  left: 0;
}

header a {
  position: relative;
  will-change: opacity;
  opacity: 1;
  transition: opacity 0.2s ease;
}

header a:hover {
  opacity: 0.7;
}

/* header a::after {
  content: '';
  position: absolute;
  left: 12%; bottom: -15px;
  width: 0;
  transition: width 0.2s ease;
  will-change: width;
  height: 3px;
  background-color: $blue;
  border-radius: 50px;
}

header a:hover::after {
  width: 70%;
} */

header .container {
  width: 95%;
  margin: auto;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* position: relative; */
}

header #notification-badge p {
  position: absolute;
  top: -10px;
  right: 5px;
  background: $orange;
  padding: 5px 5px;
  border-radius: 15px;
  color: white;
  font-size: 12px;
  height: 12px;
  min-width: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
}

// candidature count on burger icon
header i {
  position: relative;

  #notification-badge p{
    font-family: $main-font;
    top: 10px;
    right: -10px;    
  }
}

// candidature count in burger menu

header li #notification-badge p {
  top: 10px;
  right: -85px;
}


header ul {
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

header li {
  font-size: 18px;
  line-height: 31px;
  margin: 0 25px;
  cursor: pointer;
  position: relative;
}

header li .new-feature {
  position: absolute;
  top: -10px;
  right: -50px;
  line-height: 20px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: lighter;
  padding-right: 1px;
  border-radius: 5px;
  background-color: $grey3;
  color: $grey;
}

header li .new-feature.isNotifBadgeDisplayed {
  right: -95px;
}

header .header-logo {
  width: 180px;
  margin-right: 50px;
}

header .logged-nav {
  font-size: 14px;
  display: flex;
  align-items: center;
}

header .logged-nav i {
  font-size: 40px;
}

header .logged-nav .logo-container {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 1px;
  
  i {
    font-size: 50px;
  }

  &:hover {
    box-shadow: $lightgrey-shadow;
  }
}

header .logged-nav .logo-container img {
  object-fit: cover;
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

header .hamburger-menu {
  display: none;
}

/* header .button {
  position: absolute;
  right: 0;
  bottom: 30px;
} */
@media only screen and (max-width: 1024px) {
  header .container {
    display: none;
  }

  header .hamburger-menu {
    display: flex;
    flex-direction: row;
    height: 60px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 12;
  }

  header .hamburger-menu .logo {
    height: 30px;
    margin-left: 30px;
    cursor: pointer;
  }

  header .hamburger-menu i {
    font-size: 24px;
    margin-right: 30px;
    color: $main-green;
    cursor: pointer;
  }

  header .hamburger-nav {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #fff;
    border: 1px solid $grey5;
    z-index: 11;
    border-left: 1px solid rgba(200, 200, 200, 0.4);
    border-bottom: 1px solid rgba(200, 200, 200, 0.4);
    padding: 10px 0;
  }

  header .hamburger-nav li {
    padding: 10px 10px;
    margin: 0 20px;
    font-size: 16px;
    cursor: pointer;
  }

  header .hamburger-nav li:hover {
    background-color: $main-green;
    color: white;
    border-radius: 20px;
  }
}

header .message-bar {
  text-align: center;
  padding: 8px;
  background-color: lemonchiffon;
}

header .message-bar button {
  border: none;
  border-radius: 20px;
  font-weight: 100;
  font-size: 91%;
  margin-left: 5px;
  height: 30px;
  padding: 5px 15px;

  &.redirect-pricing {
    background: #25bba5;
    color: white;
  }

  &.close {
    color: black;
  }
}
