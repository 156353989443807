.multiple-select-dropdown {

    margin-bottom: 40px;

    .items-list {
        height: 280px;
        width: 433px;
        overflow-x: hidden;
        position: absolute;
        z-index: 2;
        background-color: white;
        /* overflow: scroll; */
        border: 1px solid #fbc98f;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top: none;
        padding-top: 30px;
        margin-top: -45px;

        &::-webkit-scrollbar {
            width: 0.8em;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $peach;
            border-radius: 10px;
        }

        .item {
            width: 368px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0 10px 40px;
        }


    }

    .blue-items-list {
        border-color: $blue;

        &::-webkit-scrollbar-thumb {
            background-color: $blue;
        }
    }

}

.all-selected-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 750px;

    .selected-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 90px;
        font-size: 12px;
        margin: 0 10px 10px 0;
        padding: 10px 20px;
        border: 1px solid $blue;
        border-radius: 20px;
        cursor: pointer;

        i {
            color: red;
        }
    }
}