.user-menu {
  padding: 10px 20px;
  position: absolute;
  width: 250px;
  background-color: white;
  right: 10px;
  z-index: 10;
  top: 110px;
  border-radius: 20px;
  box-shadow: $grey-shadow;
}

.user-menu li {
  font-size: 12px;
  margin: 0 20px;
  display: flex;
  align-items: center;
}

.user-menu li img {
  height: 60px;
  margin-right: 10px;
  filter: grayscale(100%) brightness(120%);
}

.user-menu li:hover {
  color: $blue;
}

.user-menu li:hover img {
  filter: unset;
}

.user-menu .signout {
  font-size: 12px;
  color: $orange;
  padding: 20px 0 10px 0;
  cursor: pointer;
  border-top: 1px solid rgba(180, 182, 182, 0.2);
}
