.create-mission .slide-three .options {
  display: flex;
  flex-wrap: wrap;
}

.create-mission .slide-three .description-input textarea {
  margin-top: 100px;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
}

.create-mission .slide-three .h4 {
  padding: 0px;
}

.create-mission .slide-three .title-input {
  margin: 15px 0 10px 0;
  display: flex;
  flex-direction: column;
}

.create-mission .slide-three .length-error {
  color: $orange;
  font-size: 12px;
  margin: -10px 0 0 15px;
}

.create-mission .slide-three .rte-hidden {
  display: none !important;
}

.create-mission .slide-three .wrapper {
  border: 1px solid $peach;
  border-radius: 10px;
  overflow: hidden;
  width: 660px;
  margin-top: 15px;
}

.create-mission .slide-three .toolbar {
  border-bottom: 1px solid $peach;
  width: 660px;
}

.create-mission .slide-three .editor {
  height: 190px;
  width: 660px;
  padding: 0px 30px;
}

.create-mission .loader {
  display: flex;
  align-items: center;
  margin: 1rem;
  p {
    margin-left: 0.5rem;
    color: $blue;
  }
}

@media only screen and (max-width: 1024px) {
  .create-mission .slide-three .options>div {
    margin-bottom: 10px;
  }

  .create-mission .orange-multiple-input {
    margin-top: 10px;
  }

  /* .slide-three .content {
    margin: 100px auto 0 auto;
  } */
  .create-mission .slide-three .content {
    width: 550px;
  }
}

@media only screen and (max-width: 900px) {
  .create-mission .slide-three .content {
    width: 450px;
  }
}