.slide-two .mail-code {
  height: 50px;
  width: 50px;
  padding: 12px;
  margin-right: 20px;
  margin-top: 80px;
  font-weight: 700;
  text-align: center;
  font-size: 50px;
  border-radius: 20px;
}

.slide-two .orange {
  border: 2px solid $peach;
}

.slide-two .green {
  border: 2px solid $main-green;
}

.slide-two .mail-code::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $light-grey;
  font-weight: 700;
  text-align: center;
  font-size: 50px;
}
.slide-two .mail-code::-moz-placeholder {
  /* Firefox 19+ */
  color: $light-grey;
  font-weight: 700;
  text-align: center;
  font-size: 50px;
}
.slide-two .mail-code:-ms-input-placeholder {
  /* IE 10+ */
  color: $light-grey;
  font-weight: 700;
  text-align: center;
  font-size: 50px;
}
.slide-two .mail-code:-moz-placeholder {
  /* Firefox 18- */
  color: $light-grey;
  font-weight: 700;
  text-align: center;
  font-size: 50px;
}
