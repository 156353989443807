@mixin search-input($color: $peach) {
  input {
    @if $color == $peach {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    margin-bottom: 10px;
    padding: 0 25px 0 50px;
    cursor: text;

    &::placeholder {
      font-family: $main-font;
      color: $grey3;
      cursor:text;
      }
    } 

    border: 1px solid $color;
    caret-color: $color;
  }
}

@mixin search-logo($color: $peach) {
  .search-logo {
    @if $color == $peach {
      position: absolute;
      left: 20px;
      bottom: 0px;
      margin: 0 0 17px 0;
    }
    color: $color;
  }
}

.search { 
  position: relative;
  width: 435px;
  height: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 3;

  @include search-input($peach);
  @include search-logo($peach);

  &.input-error {
    @include search-input($orange);
    @include search-logo($orange);
  }

  &.input-success {  
    @include search-input($blue);
    @include search-logo($blue);
  }

  &.open {
    input {
      border-radius: 20px 20px 0 0;
      border-bottom: hidden;
    }
  }
}