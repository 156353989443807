.subscription .content {
  display: flex;
  flex-direction: column;
  margin: 13vh 80px 50px 350px;
  position: relative;
}

.subscription .content h3 {
  font-size: 36px;
  line-height: 42px;
  padding: 40px 0;
}

.subscription .content h4 {
  font-size: 21px;
  line-height: 24px;
  padding: 30px 0 10px 0;
}

.subscription.content p {
  text-align: justify;
  padding: 0;
  margin-bottom: 30px;
  font-size: 18px; /*14px is better looking*/
  line-height: 21px;
  max-width: 600px;
}

.subscription form {
  display: flex;
  flex-direction: column;
}

.subscription .top-logo {
  position: absolute;
  width: 150px;
  top: 45px;
  left: 100px;
}

.subscription .backward {
  position: absolute;
  top: 18.6vh;
  left: 70px;
  color: $main-green;
  font-size: 24px;
  cursor: pointer;
}

.subscription i {
  margin-right: 20px;
}

.subscription .validate-blue, .subscription .validate-white {
  margin-top: 40px;
  position: relative;
  top: initial;
  left: initial;
}
