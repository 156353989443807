.history {
    display: flex;
    align-items: center;
    margin: 20px 0;
    height: 60px;

    &__date {
        white-space: pre;
        text-align: center;
        width: 120px;
        font-size: 18px;
        color: #CCC;
    }

    &__desc {
        width: 700px;

        h3 {
            font-size: 20px;
        }

        p {
            margin-top: 5px;
            color: #CCC;
            line-height: 18px;
            font-family: $light-font;

            b {
                font-weight: 600;
            }
        }
    }

    &__circle {
        margin: 0 20px;

        .circles {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .line {
                z-index: 1;
                position: absolute;
                background-color: $light-grey;
                opacity: 0.2;
                height: 60px;
                width: 5px;
                top: 30px;

                // &:last-child {
                //     display: none;
                // }
            }
        }

        .outer-circle {
            z-index: 2;
            border-radius: 50%;
            opacity: 0.4;
            width: 40px;
            height: 40px;
            background-color: #CCC;
        }

        .inner-circle {
            z-index: 2;
            position: absolute;
            border-radius: 50%;
            width: 66%;
            height: 66%;
            opacity: 1;
            background-color: #CCC;
        }

        .circle-green-light {
            background-color: $light-green;
        }

        .circle-orange {
            background-color: $orange;
        }

        .circle-green {
            background-color: $main-green;
        }

        .circle-yellow {
            background-color: $peach;
        }

        .circle-blue {
            background-color: $blue;
        }
    }

    &:last-child {
        .line {
            display: none;
        }
    }

}