.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;

  .circle {
    height: 24px;
    min-width: 24px;
    border: 1px solid $peach;
    position: relative;
    margin-right: 10px;
    border-radius: 18px;
    cursor: pointer;

    &__error {
      border: 1px solid $orange !important;
    }

    &__forbidden {
      border: 1px solid lightgrey;
    }
  }

  .link:hover {
    text-decoration: underline;
  }

  .square {
    height: 28px;
    min-width: 28px;
    border: 1px solid $peach;
    position: relative;
    margin-right: 10px;
    border-radius: 10px;
    cursor: pointer;
    &.radio {
      border-radius: 100%;
      height: 20px;
      min-width: 20px;
    }
  }

  .forbidden {
    cursor: not-allowed;
  }

  .new-feature {
    margin-left: 2px;
    font-size: 10px;
    padding: 3px 5px;
    border-radius: 5px;
    background-color: $grey3;
    color: $grey;
  }
}

.checkbox-blue {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
}

.checkbox-blue .circle {
  height: 24px;
  min-width: 24px;
  border: 1px solid $blue;
  position: relative;
  margin-right: 10px;
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.checkbox-blue .square {
  height: 28px;
  min-width: 28px;
  border: 1px solid $blue;
  position: relative;
  margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.checkbox-blue .circle .inner-blue-circle {
  background-color: $blue;
  height: 16px;
  width: 16px;
  border-radius: 8px;
}

.checkbox-blue .square .inner-blue-circle {
  background-color: $blue;
  height: 16px;
  width: 16px;
  border-radius: 4px;
}

.checkbox-blue .square.radio .inner-blue-circle {
  border-radius: 100%;
  height: 10px;
  width: 10px;
}
