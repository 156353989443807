.highlighted-tip {
  position: fixed;
  z-index: 1000;
}

.highlighted-tip .text {
  width: 200px;
  border: 1px solid $blue;
  color: $grey;
  background-color: white;
  padding: 15px 10px;
  font-size: 12px;
  border-radius: 8px;
}

/* .highlighted-tip .light-blue-circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -25px;
  bottom: -25px;
} */

/* .highlighted-tip .blue-circle {
  left: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: $blue;
} */
