.substitute-page .courses {
  background-color: white;
  border-radius: 15px;
  margin: 10px;
  padding: 20px;
}

.substitute-page .course {
  width: 100%;
  margin: 20px 0;
  font-size: 18px; // 16px ?
  display: flex;
  align-items: baseline;
  
  .col-image {
    width: 12%;
    margin-right: 1em;
    img {
      width: 100%;
      height: auto;
    }
  }

  .col-description {
    width: 78%;
    
    .courseorganization {
      padding: 5px 0;
      font-size: 16px;
    }

    .coursedate {
      padding: 5px 0;
      font-size: 14px;
    }

    .coursedescription {
      padding: 5px 0;
      font-size: 12px;
    }
  }
}