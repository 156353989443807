.ListSelectMultiple {
  padding: 10px 20px;

  input.ListSelectMultiple__input {
    font-family: "Arial Rounded MT Bold", sans-serif;
    border: none;
    font-size: 14px;
    border-bottom: 1px solid black;
    box-sizing: content-box;
    padding: 0 0 5px;
    width: 100%;
    color: $grey;

    &::placeholder {
      font-size: 14px;
      font-family: "Arial Rounded MT Bold", sans-serif;
      color: $grey3;
    }

    &.orange-border {
      border-color: $peach;
    }

    &.blue-border {
      border-color: $blue;
    }

    &.error-border {
      border-color: $orange;
    }
  }
}
