.info-box {
    display: flex;
    background-color: $blue;
    opacity: 0.5;
    padding: 10px 20px;
    border-radius: 10px;
    color: white;

    &__icon {
        display: flex;
        align-items: center;

        i {
            color: $blue;
            background-color: white;
            margin-right: 10px;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__close i {
        cursor: pointer;
        color: white;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 20px;
        margin: unset !important;
    }
}