.subscription .step {
  position: fixed;
  bottom: 0;
  width: calc(100% - 447px);
  height: 20px;
  overflow: hidden;
}

.subscription .blue-step {
  background-color: $blue;
}

.subscription .grey-step {
  background-color: $light-grey;
}

.subscription .step-bar-1 {
  position: absolute;
  left: 0;
  bottom: -10px;
  height: 20px;
  width: 25%;
  z-index: 4;
}

.subscription .step-round-1 {
  position: absolute;
  left: 24%;
  bottom: -12px;
  border-radius: 15px;
  height: 30px;
  width: 30px;
  z-index: 4;
}

.subscription .step-bar-2 {
  position: absolute;
  left: 24%;
  bottom: -10px;
  height: 20px;
  width: 25%;
  z-index: 3;
}

.subscription .step-round-2 {
  position: absolute;
  left: 48%;
  bottom: -12px;
  border-radius: 15px;
  height: 30px;
  width: 30px;
  z-index: 3;
}

.subscription .step-bar-3 {
  position: absolute;
  left: 49%;
  bottom: -10px;
  height: 20px;
  width: 25%;
  z-index: 2;
}

.subscription .step-round-3 {
  position: absolute;
  left: 73%;
  bottom: -12px;
  border-radius: 15px;
  height: 30px;
  width: 30px;
  z-index: 2;
}

.subscription .step-bar-4 {
  position: absolute;
  left: 74%;
  bottom: -10px;
  height: 20px;
  width: 25%;
  z-index: 1;
}

.subscription .step-round-4 {
  position: absolute;
  left: 98.5%;
  bottom: -12px;
  border-radius: 15px;
  height: 30px;
  width: 30px;
  z-index: 1;
}
