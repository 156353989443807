.slide-five {
  display: flex;
  flex-direction: column;

  .content {
    .title {
      font-size: 36px;
      line-height: 42px;
      padding: 40px 0 5px 0;
    }

    .category {
      position: absolute;
      top: -31px;
      right: 5px;
      background-color: $blue;
      padding: 5px;
      border-radius: 5px 5px 0 0;
      color: white;
    }

    .user-summary,
    .ets-summary {
      font-weight: 100;
      border: solid 1px $grey4;
      padding: 20px;
      margin-top: 45px;
      border-radius: 5px;
      position: relative;
      width: 500px;
      line-height: 20px;
      font-size: 14px;

      div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
          color: $grey2;
        }
      }
    }

    .confirmation-input {
      margin-top: 30px;
      color: $blue;
      font-size: 17px;

      &:hover {
        color: $blue;
      }

      input {
        margin-right: 10px;
        padding: 2px;
        border: 1px solid red;
      }
    }

    .validation-subscription {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .validate-blue {
        margin-top: 0;
        margin-right: 20px;
      }

      .validate-white {
        margin-top: 0;
        margin-right: 20px;
      }
    }
  }
}
