.support-thumbnail {
  width: 230px;
  padding: 3px;
  margin: 0 30px 30px 0;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  background-color: white;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    margin-left: 20px;
  }

  .title {
    font-size: 20px;
    margin: 30px auto;
    color: black;
    text-decoration: underline;
  }

  .role {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .avatar img {
    height: 40px;
    width: 40px;
    border-radius: 10px;
  }

  .identity {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    margin-bottom: 20px;

    > div:first-child {
      margin-top: 5px;
    }

    > div:last-child {
      margin-bottom: 5px;
    }
  }

  .mail {
    font-size: 12px;
    margin-bottom: 30px;

    i {
      margin-right: 8px;
    }

    &:hover,
    a:hover {
      color: $blue;
    }
  }

  .cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-bottom: 20px;
    font-size: 13px;
    text-align: center;
    line-height: 15px;

    .hubspot-error {
      margin-bottom: 10px;
      color: $orange;

      * {
        color: $orange;
      }
    }
  }

  .validate-blue {
    width: 200px;
    font-size: 12px;
    height: 30px;
  }
}
