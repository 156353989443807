.card {
  width: 450px;
  box-sizing: border-box;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  padding: 20px;
  position: relative;

  &:not(:last-child) {
    margin-right: 50px;
  }

  .toggle-simulator {
    padding: 10px 15px;
    background-color: $grey4;
    transition: all 0.3s ease;
    border-radius: 20px;
    margin-bottom: 20px;

    &:hover {
      color: white;
      padding: 10px 25px;
    }

    &.smile,
    &.happy {
      visibility: hidden;
    }

    &.zen {
      border: 1px solid white;

      &:hover {
        background-color: black;
      }
    }
  }

  .call-to-action {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 55px;

    button.current-plan {
      font-size: 16px;
      padding: 10px 15px;
      color: $blue;
      background-color: inherit;
      border: none;
      font-weight: bold;
    }

    button:not(.current-plan) {
      font-size: 16px;
      transition: all 280ms ease;
      padding: 10px 15px;
      color: $blue;
      background-color: inherit;
      border: 1px solid $blue;
      border-radius: 50px;

      &:hover {
        background-color: $blue;
        color: white;
        padding: 10px 25px;
      }
    }

    .loader {
      display: flex;
      align-items: center;
      padding: 10px 15px;

      > div {
        margin-left: 5px;
      }
    }

    .success,
    .error {
      text-align: center;
    }

    .success {
      font-size: 15px;
      line-height: 20px;
      min-height: 43px;
    }

    .error {
      font-size: 15px;
      color: $orange;
      margin-top: 10px;
      line-height: 15px;
      max-width: 400px;
    }
  }

  &.smile {
    .title {
      color: grey;
    }
  }

  &.happy {
    .title {
      color: $main-green;
    }
  }

  &.zen {
    box-shadow: 0 0 3px black;

    .title {
      color: black;
    }

    .details {
      border-top: 1px solid black;

      .category {
        color: black;
        margin: 10px auto;
        padding: 5px;
      }
    }
  }

  .title {
    text-transform: uppercase;
    padding: 10px 0;
  }

  .title,
  .description,
  .price,
  .details-price {
    text-align: center;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0;
    padding: 40px;
  }

  .description {
    margin-bottom: 20px;
    font-size: 14px;
    text-transform: uppercase;
    min-height: 30px;
  }

  .price {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 15px;

    span:first-child {
      font-size: 35px;
    }

    span:nth-child(2) {
      max-width: 150px;
      display: flex;
      justify-content: flex-start;

      em {
        font-size: 10px;
        margin-left: 2px;
        color: $grey2;
      }
    }
  }

  .details-price {
    font-size: 15px;
    color: $grey2;
    padding-bottom: 20px;
    display: flex;
    min-height: 14px;
    align-items: flex-start;

    em {
      font-size: 9px;
      margin-left: 2px;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    font-size: 13.5px;
    color: $grey;
    min-height: 20px;
    padding: 40px 30px;
    border-top: 1px solid $grey3;

    .label {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      i {
        margin-right: 50px;
        color: $main-green;
      }

      em {
        font-size: 10px;
        margin-left: 2px;
        color: $grey2;
      }
    }

    .category {
      font-size: 16px;
      text-align: center;
      margin-bottom: 5px;
      color: $main-green;
    }
  }
}
