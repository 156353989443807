.slide-three .role,
.slide-three .role-blue {
  padding: 15px 0;
  width: 220px;
  border-radius: 40px;
  margin: 2px 30px 32px 0;
  font-family: $main-font;
  color: $grey;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
}

.slide-three .role {
  border: 1px solid $peach;
}

.slide-three .role-blue {
  border: 1px solid $blue;
}

.slide-three .role:focus,
.slide-three .role:active {
  border: 3px solid $blue;
  margin: 0 30px 30px 0;
  background-color: #fff;
}

.slide-three button:active,
.slide-three button:focus {
  border: $blue 2px solid;
  background: #ececec;
}
