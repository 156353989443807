.filters {
  width: 235px;
  height: calc(100% - 200px);
  padding: 40px;
  background-color: white;
  position: fixed;
  margin-top: 120px;
  -webkit-transition: top 1s ease;
  -moz-transition: top 1s ease;
  -o-transition: top 1s ease;
  transition: top 1s ease, height 1s ease;
  top: 0;
  overflow-y: scroll;
  z-index: 10;

  &::-webkit-scrollbar-track {
    background-color: #f5f5f500;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f500;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $grey3;
  }

  // HEADER
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    h2 {
      font-size: 24px;
    }

    .reload {
      font-size: 18px;
      margin-top: 3px;
      cursor: pointer;
    }
  }

  h3 {
    margin-bottom: 15px;
    color: $grey;
    &::before {
      content: " • ";
    }
  }

  // SELECT PROF
  .profession {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    z-index: 6;

    .ListSelect {
      margin-bottom: 0;
      padding-bottom: 40px;
      font-size: 14px;
      &__container {
        z-index: 6;
      }
      &__list__item {
        font-size: 12px;
      }
      &__input {
        font-family: "Arial Rounded MT Bold", sans-serif;
        border: none;
        font-size: 14px;
        border-bottom: 1px solid $blue;
        box-sizing: border-box;
        padding: 0 0 5px;
        color: $blue;
        &::placeholder {
          font-size: 14px;
          font-family: "Arial Rounded MT Bold", sans-serif;
          color: $grey3;
        }
      }
    }
  }

  // SWITCH TYPE
  .type {
    z-index: 5;
    display: flex;
    justify-content: space-between;

    &-select {
      width: 70px;
      height: 70px;
      font-size: 13px;
      padding: 10px 15px;
      border: 1px solid $light-grey;
      color: $grey;
      border-radius: 15px;
      text-align: center;
      margin: 5px 0 15px 0;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      &.selected {
        color: #fff;
        background-color: $main-green;
        border: 1px solid $main-green;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  span.location-label {
    color: $blue;
    border: $blue 1px solid;
    padding: 3px 10px;
    border-radius: 15px;
    transition: all ease 300ms;
    margin: 5px;
    font-size: 12px;

    &:hover {
      cursor: pointer;
      border-color: $orange;
      background-color: $orange;
      color: white;
    }
  }

  // SLIDER
  .experience,
  .location {
    z-index: 3;
  }

  .experience-bar,
  .location-bar {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
  }

  .vertical-bar {
    height: 10px;
    width: 1px;
    border-left: 1px solid black;
    padding: 0;
    margin: 0;
  }

  .horizontal-bar {
    height: 1px;
    width: 55px;
    border-bottom: 1px solid black;
    padding: 0;
    margin: 0;
  }

  .experience-text,
  .location-text {
    font-size: 13px;
    margin: 5px 0 20px 0;
  }

  // SKILLS
  .orange-selector,
  .blue-selector {
    margin-bottom: 5px;
  }

  .skills-list {
    display: flex;
    flex-wrap: wrap;
    > span {
      color: $blue;
      border: $blue 1px solid;
      padding: 3px 10px;
      border-radius: 15px;
      margin: 5px;
      font-size: 12px;

      &:hover {
        cursor: pointer;
        border-color: $orange;
        background-color: $orange;
        color: white;
        text-decoration: line-through;

        &::before {
          content: " x ";
          text-decoration: none
        }
      }
    }
  }
  .skills {
    z-index: 1;
    .list-global {
      width: 230px;

      .list {
        width: 230px;
        padding-top: 10px;
      }

      .items-list {
        width: 228px;
        height: 250px;
        li {
          padding: 0 10px;
        }
      }
    }
  }

  // OTHERS
  .checkbox .description,
  .checkbox-blue .description {
    font-size: 13px;
    margin: 12px 0;
    z-index: 1;
  }

  .light-text {
    font-size: 14px;
    margin: 0 0 15px 10px;
  }

  .sub-filter {
    margin-bottom: 25px;
    border-bottom: 2px solid $grey5;
    padding-bottom: 15px;
  }
}
